import { useContext } from "react";
import { Link } from "react-router-dom";

import HoverProduct from "./HoverProduct";
import TotalPrice from "./TotalPrice";
import { ContextPro } from "../../App";

import { Admin, User } from "../../utils/AdminUser";

export default function HoverCart() {
  const { idcart, SetIdCart } = useContext(ContextPro);
  return (
    <div className="hover-cart">
      <div className=" position-relative hover-cart-1">
        <ul
          style={{
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          {Admin() ? (
            ""
          ) :  
          (
           localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart'))?.map((e) => <HoverProduct data={e } />): idcart[0]?.cartProducts?.map((e) => <HoverProduct data={e } />)
          ) }

          {(
            <li
              style={{
                position: "sticky",
                bottom: "-1px",
                background: "white",
              }}
            >
              {" "}
              <TotalPrice data={idcart[0]?.totalAmount} />
            </li>
          ) }
        </ul>
      </div>
    </div>
  );
}
/* JSON.parse(localStorage.getItem('cart')) */