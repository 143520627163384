import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { faPhp } from "@fortawesome/free-brands-svg-icons";
import { ContextPro } from "../../../App";
import BreadCrumb from "../../BreadCrumb";
import BtnAdmin from "../BtnAdmin";
import ChooseImg from "../ChooseImg";
import InputComponent from "../InputComponent";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd } from "../../../utils/utilsFuc";
import { Based64 } from "../../../utils/convertToBased64";

export default function EditBrand() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const loc = useLocation();
  const { setDataBrand } = useContext(ContextPro);
  const [d, se] = useState();
  const [data, setData] = useState({
    id: loc.state.id,
    name: loc.state.name,
  });
  function setPhoto(dataPhoto) {
    
    se(Based64(dataPhoto[0]));
  }

  useEffect(() => {
    if (d) {
      setData({
        id: loc.state.id,
        name: loc.state.name,
        photo: d,
      });
    } else {
      setData({
        id: loc.state.id,
        name: loc.state.name,
      });
    }
  }, [d]);

  return (
    <div className=" container">
      <BreadCrumb />

      <div className=" brand-data">
        <InputComponent title={"Name"}>
          <input
            type="text"
            value={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
           
            }}
          />
        </InputComponent>
        <InputComponent title={"Photo File"}>
          {" "}
          <ChooseImg onclick={setPhoto} />
        </InputComponent>
      </div>
      <div className=" d-flex gap-4">
        <BtnAdmin
          title={loader ? <div className="loader"></div> : "save"}
          bgcolor="#ff0022"
          onclick={() => {
            setLoader(true);
            urlApiEd(adminPanelAPIs.Brand.editBrandURL, {
              id: loc.state.id,
              name: data.name,
              photo: d,
            }).then((res) => {
              setDataBrand((prev) => {
                let prevClone = [...prev];
                let editedbrandIndex = prevClone.findIndex(
                  (item) => item.id === res.id
                );
                prevClone.splice(editedbrandIndex, 1, res);
                return prevClone;
              });
              navigate(-1);
              setLoader(false);
            });
          }}
        />

        <BtnAdmin
          title={"Back to List"}
          bgcolor="blue"
          onclick={() => {
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
}
