import MobMidBar from "./MobMidBar";
import MobTopBar from "./MobTopBar";
import "./mobheader.css";

export default function MobHeader(){
    return(
        <div className="position-relative z-3 mob-head">
            <MobTopBar/>
            <MobMidBar/>
        </div>
    )
}