import React, {  useState } from "react";
import { useLocation } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { EffectFade, FreeMode, Navigation, Thumbs } from "swiper/modules";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


export default function SwiperViewQuick({ photo = [] }) {
  const location = useLocation();
  
  const [thumbsSwiper, setThumbsSwiper] = useState();
  return (
    <div className="swpier-quick">
      <div className="swpier-quick-1">
        <Swiper
          style={{}}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1}
          effect={"fade"}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Thumbs, EffectFade]}
          className=""
        >
          {(photo)?.map((photo) => (
            <SwiperSlide>
              <img src={photo?.photo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swpier-quick-2">
        <Swiper
          onSwiper={setThumbsSwiper}
          navigation={true}
          spaceBetween={20}
          slidesPerView={4}
          freeMode={true}
          modules={[FreeMode, Navigation, Thumbs]}
          direction="horizontal"
        >
          {(photo)?.map((photo) => (
            <SwiperSlide>
              <img src={photo?.photo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
