import React from "react";
import { Link } from "react-router-dom";

import { faGem } from "@fortawesome/free-regular-svg-icons";
import { faList, faTimeline } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageHome() {
  return (
    <>
      <div className=" home-admin">
        <div className=" container">
          <div className=" grid-home">
            <Link to={"/Admin/Categroy"}>
              <FontAwesomeIcon size="5x" icon={faTimeline} rotation={180} />
              <div>Category</div>
            </Link>
            <Link to={"/Admin/Order"}>
              <FontAwesomeIcon size="5x" icon={faList} />
              <div>Orders</div>
            </Link>
            <Link to={"/Admin/Brand"}>
              {" "}
              <FontAwesomeIcon size="5x" icon={faGem} />
              <div>Brand</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
