import { useContext } from "react";
import { Link } from "react-router-dom";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";

import { User } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiEd, urlApiGet } from "../../utils/utilsFuc";

export default function HoverProduct({ data }) {
  const { SetIdCart, idcart } = useContext(ContextPro);


  const idProduct = data?.productId || data?.id;
  function cartDelete(id) {
    urlApiEd(
      adminPanelAPIs.cartAdd.deleteCart(id),
      {},
      {
        "Content-Type": "application/json",
        " Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    ).then((e) => {
      urlApiEd(
        adminPanelAPIs.Cart.getCart,
        {},
        {
          "Content-Type": "application/json",
          " Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
      ).then((t) => {
        SetIdCart(t.list);
      });
    });
  }
  const getElementsfromLocalStorage = () => {
    let elements = [];
    if (localStorage.getItem("cart")) {
      elements = JSON.parse(localStorage.getItem("cart"));
    }
    return elements;
  };

  const removeElementLocalStorage = (id) => {
    let elements = getElementsfromLocalStorage();
    elements = elements.filter((element) => element.id !== id);
    localStorage.setItem("cart", JSON.stringify(elements));
    SetIdCart(JSON.parse(localStorage.getItem("cart")));
  };
  return (
    <li className="product">
      <tbody>
        <tr>
          <td className=" row-t-body td-image">
            <img
              src={
                localStorage.getItem("cart")
                  ? data?.productPhoto[0]?.photo
                  : data?.productPhoto
              }
              alt=""
            />
          </td>
          <td className=" row-t-body td-name">
            <Link to="">{data?.productTitle}</Link>
          </td>
          <td className=" row-t-body td-count">{data?.count}</td>

          <td className=" row-t-body td-price">EGP {data?.totalAmount}</td>
          {User() ? (
            <td
              className=" row-t-body td-close"
              title="Remove"
              onClick={() => cartDelete(idProduct)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </td>
          ) : (
            <td
              className=" row-t-body td-close"
              title="Remove"
              onClick={() => removeElementLocalStorage(idProduct)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </td>
          )}
        </tr>
      </tbody>
    </li>
  );
}
