import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';
import { urlApiEd } from '../../utils/utilsFuc';
import { adminPanelAPIs } from '../../utils/config';
import CanvasInCenter from '../Commons/CanvasInCenter';
import MassageError from '../MassageError';
import Loader from '../loader';

export default function Orders({e,setData}) {

    const [open, setOpen] = useState(false);
    const [idOrder, setIdOrder] = useState(null);
    const [orderStatus, setOrederStatus] = useState(null);
    const [error, setError] = useState(false);
    const [load, setLoad] = useState(false);
    const Status ={
      1:"approved",
      2:"pending...",
      3:"rejected",
    }
    return (
      <div className="mt-4 container position-relative ">
         <button className="fs-6 text-white   px-2 top-0 border-0 bg-danger position-absolute start-100 z-3"
          onClick={(t)=>{
            setLoad(true)
            urlApiEd(adminPanelAPIs.Order.deleteOrder(e?.id),{},{
              "Content-Type": "application/json",
             "Authorization": `Bearer ${localStorage.getItem("token")}`, 
            }).then((e)=>{
              setLoad(false)
              setData((prev) => prev?.filter((t) => (t.id !== e.id && t)));
              
              
            }).catch((e)=>{
              setLoad(false)
              setError(true)
              
              
            })
            
          }}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
        <div
          onClick={() => setOpen(!open)}
          aria-controls="order-admin"
          aria-expanded={open}
          className=" d-flex justify-content-between align-items-center bg-success-subtle px-2 border-bottom border-1 border-black"
        >
         
          <span className="fs-5 fw-medium col-2 ">
            Address  <div className=" fw-light">{e?.address}</div>
          </span>
          <span className="fs-5 fw-medium col-2 ">
            Total  <div className=" fw-light">{e?.totalAmount}</div>{" "}
          </span>
        
          <span className="fs-5 fw-medium col-2 ">
            Status  <div style={{width:"fit-content"}} className={`fw-light  fs-6 text-white me-3  ${e?.orderStatus===2&&'bg-warning'} ${e?.orderStatus===3&&'bg-danger '}  ${e?.orderStatus===1 &&'bg-success'} py-2 px-3 rounded-2`}>{Status[e?.orderStatus]}</div>{" "}
          </span>
         
          <button className="fs-3 text-white my-2 px-4 border-0 bg-success">
            {open ? "-" : "+"}
          </button>
        </div>
        <Collapse in={open}>
          <div id="order-admin " className="">
            <div className=" px-2 d-flex justify-content-between align-items-center">
              <span
                style={{
                  width: "90px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  flexGrow: "1",
                }}
              >
                title
              </span>
              <span
                style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
              >
                image
              </span>
              <span
                style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
              >
                count
              </span>
              <span
                style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
              >
                price
              </span>
              <span
                style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
              >
                discount
              </span>
              <span
                style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
              >
                priceTotal
              </span>
            </div>
            {e?.orderItems?.map((e) => (
              <div className="p-2 d-flex justify-content-between align-items-center">
                <span
                  style={{ width: "90px", wordBreak: "break-all", flexGrow: "1" }}
                >
                  {e?.product?.title}
                </span>
                <img
                  src={e?.product?.productAttchments[0]?.photo}
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "contain",
                  }}
                />
                <span
                  style={{
                    width: "90px",
                    textAlign: "center",
                    wordBreak: "break-all",
                  }}
                >
                  {e?.count}
                </span>
                <span style={{ width: "90px", wordBreak: "break-all" }}>
                  {e?.price}
                </span>
                <span style={{ width: "90px", wordBreak: "break-all" }}>
                  {e?.discount}
                </span>
                <span style={{ width: "90px", wordBreak: "break-all" }}>
                  {e?.totalAmount}
                </span>
              </div>
            ))}
        
          </div>
          
        </Collapse>
        {load?<Loader></Loader>:""}
            <CanvasInCenter showcenter={error} setError={setError}>
              <MassageError>
              you can't delete this order
              </MassageError>
            </CanvasInCenter>
      </div>
    );
  
}
