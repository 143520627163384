import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";

import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
export default function CollsCart({ children, text }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  return (
    <div className="wrap-filter">
      <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="colls-open"
      >
        <Link
          preventScrollReset
          reloadDocument
         
          onClick={(e) => {
            if (!open) {
              e.preventDefault();
            } else {
            }
          }}
        >
          {text}
        </Link>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text" ref={ref}>
          <div className="item-filter">{children}</div>
        </div>
      </Collapse>
    </div>
  );
}
