import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  faCaretRight,
  faCartArrowDown,
  faLariSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";

export default function MainItems({ item }) {
  const { idUser } = useContext(ContextPro);
  let p = [1, 3, 56, 10, 2, 84, 99, 0];
  const ref = useRef();
  function show(i) {
    return i.current.classList;
  }
  return (
    <li
      className="section-link"
      onMouseEnter={() => {
        show(ref).add("section-main");
      }}
      onMouseLeave={() => {
        show(ref).remove("section-main");
      }}
    >
      <Link
        className="section-item"
        to={`${
          idUser?.roles?.length ? "/Admin" : ""
        }/product/category/${item.name.split("/").join("-")}`}
        state={{
          id: item.id,
        }}
      >
        {item.name}
        <FontAwesomeIcon icon={faCaretRight} />
      </Link>
      <ul ref={ref} className=" link-main">
        {item.childs.map((subItem) => {
          return !subItem?.childs?.length ? (
            <li>
              <Link
                className="section-item"
                to={`${
                  idUser?.roles?.length ? "/Admin" : ""
                }/product/category/${subItem.name.split("/").join("-")}`}
                state={{
                  id: subItem.id,
                }}
              >
                {subItem.name}
              </Link>
            </li>
          ) : (
            <MainItems item={subItem} />
          );
        })}
      </ul>
    </li>
  );
}
//              <Link className="section-item">{e.name}</Link>
/*  {data?.map((e) =>
          e.parentId == cond && e.id != cond ? (
            <li>
              <Link className="section-item">{e.name}</Link>
            </li>
          ) : (
            childern
          )
        )} */
