import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function Tip({ text, children }) {
  const ref = useRef();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" ref={ref} {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      <Button className="border-0">{children}</Button>
    </OverlayTrigger>
  );
}

export default Tip;
