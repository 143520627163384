import { useContext, useEffect, useState } from "react";
import axios from "axios";

import CanvasInCenter from "../Commons/CanvasInCenter";
import Loader from "../loader";
import { ContextPro } from "../../App";
import { login } from "../../utils/auth";
import { adminPanelAPIs } from "../../utils/config";

export default function SignIn() {

  const [error, setError] = useState(false);
  const [load, setload] = useState(false);
  const [data, setdata] = useState({
    name: "",
    password: "",
  });
  useEffect(() => {}, [data]);
  function fetchuser(name, password) {
    setload(true);
    axios
      .post(
        adminPanelAPIs.Account.userLogin,
        {
          email: `${name}`,
          password: `${password}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((e) => {
        setload(false);
  localStorage.setItem("token", e.data.data.token);
        
        
       login(e.data.data.token);
       window.open("/", "_self");

      })
      .catch((ee) => {
  
        
        setload(false);
        setError(true);
      })
      .finally(() => {
        setload(false);
      });
  }
  return (
    <div className=" container-login fs-6  sign-in">
      {load ? <Loader /> : ""}
      <form action="" className=" d-flex flex-column gap-4 border-secondary">
        <label>
          {" "}
          <span>Email</span>
          <input
            type="text"
            placeholder="Enter Email"
            value={data.name}
            className="  w-100"
            onChange={(e) => {
              setdata({ ...data, name: e.target.value });
            }}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            type="password"
            placeholder="Enter Password"
            className=" w-100"
            value={data.password}
            onChange={(e) => {
              setdata({ ...data, password: e.target.value });
            }}
          />
        </label>
        <div className=" btn-login">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              fetchuser(data.name, data.password);
            }}
          >
            Sign in
          </button>
          <CanvasInCenter setError={setError} showcenter={error}>
            <div className=" my-5">pealse enter data correct</div>
          </CanvasInCenter>
        </div>
      </form>
    </div>
  );
}
