import { useContext, useEffect, useState } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import {
  Link,
  useLocation,
  useMatch,
  useMatches,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterBody from "./FilterBody";
import Products from "./Products";
import { ContextPro } from "../../../App";
import BtnAdmin from "../../Admin/BtnAdmin";
import BreadCrumb from "../../BreadCrumb";
import CanvasInCenter from "../../Commons/CanvasInCenter";
import Loader from "../../loader";
import CanvasFilter from "../CanvasFilter";

import { adminPanelAPIs } from "../../../utils/config";
import { detectMob, urlApiGet } from "../../../utils/utilsFuc";

import "./cart.css";
import "./filter.css";

export default function PageProduct() {
  //todo
  const [pageSize, setPageSize] = useState(10);
  const { dataCate, dataBrand } = useContext(ContextPro);
  const path = useParams();
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [indexPagesApi, setIndexPagesApi] = useState(1);
  const [indexPagesApiSearch, setIndexPagesApiSearch] = useState(1);
  const [indexPage, setIndexPage] = useState(1);
  const [indexSearch, setIndexSearch] = useState(1);
  const location = useLocation();
  const [more, setMore] = useState(true);
  const [load2, setload2] = useState(false);

  const [load, setload] = useState(true);
  const [load3, setload3] = useState(false);
  const [error, setError] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  // const [categoryFilter, setCategoryFilter] = useState("");
  // const [brandFilter, setBrandFilter] = useState("");
  // const [maxPriceFilter, setMaxPriceFilter] = useState("");
  const navigate = useNavigate();

  const id =
    location?.state?.id ||
    dataCate?.find((e) => e.name === path.id.split("-").join("/"))?.id ||
    dataBrand?.find((e) => e.name === path.id)?.id;
 
  useEffect(() => {
   

    setFilterObj({});
    setDataProduct([]);
    setIndexPage(1);
    setIndexPagesApiSearch(1);
    setMore(true);
    setload(true);

    if (!id && !dataCate.length && !dataBrand.length) return;
    else if (!id && dataCate.length && dataBrand.length) {
    

      navigate("/");
      setDataProduct([]);

      return;
    }

    /* todo */
    const requestPayload =
      path.type === "brand"
        ? { brandId: id, pageSize: pageSize }
        : path.type === "category"
        ? {
            categoryId: id,

            pageSize: pageSize,
          }
        : navigate("/");
   id&&urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
      (e) => {
        

        setIndexPagesApi(e.totalPages);
        e?.list?.length && setDataProduct([...e.list]);
        //!e.length && setDataProduct([]);
        (e.totalPages === 1 || e.totalPages === 0) && setMore(false);

        setload(false);
      }
    );
  }, [id, dataCate.length, dataBrand.length, pageSize]);

  useEffect(() => {
    if (indexPage > 1 && dataFilter.length === 0) {
      setload2(true);
      const requestPayload =
        path.type === "brand"
          ? { brandId: id, pageIndex: indexPage, pageSize: pageSize }
          : path.type === "category"
          ? {
              categoryId: id,
              pageIndex: indexPage,
              pageSize: pageSize,
            }
          : navigate("/");
      urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
        (e) => {
          setload2(false);

          e?.list?.length && setDataProduct([...dataProduct, ...e.list]);
          e.totalPages <= indexPage && setMore(false);

          setload(false);
        }
      );
    }
  }, [indexPage]);
  const hanldeFilterProducts = (filterParams) => {
    const { isClear = false, name, value } = filterParams;
    // isClear = true ---> get all products
    // else ---> use filter
    if (isClear) {
      indexPage < indexPagesApi && setMore(true);
      setFilterObj({});
      setDataFilter([]);
      /*  const requestPayload =
        path.type === "brand"
          ? { brandId: id, pageIndex: index }
          : path.type === "category"
          ? {
              categoryId: id,
              pageIndex: 1,
            }
          : navigate("/");
      urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
        (e) => {
          setload2(false);
          console.log(e);
          setDataProduct(e);
          !e.length && setMore(false);

          setload(false);
        }
      ); */
      // fetch("", )
    } else {
      // filter obj ===> minPrice = 100
      // current I made change in maxPrice to be 20000
      let filterObjClone = { ...filterObj, [name]: value };
      if (!value) {
        delete filterObjClone[name];
      }
      setFilterObj(filterObjClone);
      setIndexSearch(1);

      //setMore(true);

      // fetch("", filterObjClone)
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(filterObj).length !== 0) {
        setMore(true);
        indexSearch === 1 && setload3(true);
        const requestPayload =
          path.type === "brand"
            ? { brandId: id, pageIndex: indexSearch, pageSize: pageSize }
            : path.type === "category"
            ? {
                categoryId: id,
                pageIndex: indexSearch,
                pageSize: pageSize,
              }
            : navigate("/");
        urlApiGet(adminPanelAPIs.Product.getProductURL, {
          ...requestPayload,
          ...filterObj,
        })
          .then((e) => {
          

            setload2(false);
            setload3(false);

            indexSearch === 1
              ? setDataFilter(e.list)
              : setDataFilter((prev) => [...prev, ...e.list]);
            setIndexPagesApiSearch(e.totalPages);
            e?.totalPages <= indexSearch && setMore(false);
            (e?.totalPages === 1 || e?.totalPages === 0) && setMore(false);
            !e?.list?.length && setError(true);
          })
          .catch((e) => {
            setload3(false);
         
          })
          .finally(() => {
            setload3(false);
          });
      } else {
        setDataFilter([]);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterObj, indexSearch]);
 

  return (
    <div className="all-body container">
      <BreadCrumb />
      <h3 className="px-3 my-4 fs-3">{path.id}</h3>
      <div className="content-body ">
        {detectMob() ? (
          <CanvasFilter>
            <FilterBody
              filterObj={filterObj}
              hanldeFilterProducts={hanldeFilterProducts}
              dataFilter={dataFilter || dataProduct}
            />
          </CanvasFilter>
        ) : (
          <FilterBody
            filterObj={filterObj}
            hanldeFilterProducts={hanldeFilterProducts}
            dataFilter={dataFilter || dataProduct}
          />
        )}
        {load ? (
          <Loader></Loader>
        ) : indexPagesApiSearch === 0 && Object.keys(filterObj).length ? (
          ""
        ) : (
          <Products
            data={dataFilter.length ? dataFilter : dataProduct}
            setdata={setDataProduct}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        )}
      </div>
      {more ? (
        <div
          style={{ width: "fit-content", margin: "auto" }}
          onClick={() => {
            if (Object.keys(filterObj).length) {
              setload2(true);
              setIndexSearch(indexSearch + 1);
            } else {
              setload2(true);
              setIndexPage(indexPage + 1);
            }
          }}
        >
          {load2 ? <div className=" loader"></div> : ""}
          <BtnAdmin title={"More Data"} />
        </div>
      ) : (
        ""
      )}
      {load3 ? <Loader></Loader> : ""}
      {
        <CanvasInCenter showcenter={error} setError={setError}>
          <div className=" my-5">
            No products with these specifications available.
          </div>
        </CanvasInCenter>
      }
    </div>
  );
}
/*   dataProduct.length ? (
    <div className="all-body container">
      <BreadCrumb />
      <h3 className="px-3 my-4 fs-3">{path.id}</h3>
      <div className="content-body ">
        {detectMob() ? (
          <CanvasFilter>
            <FilterBody dataFilter={dataProduct} />
          </CanvasFilter>
        ) : (
          <FilterBody dataFilter={dataProduct} />
        )}

        {load ? <Loader /> : <Products data={dataProduct} />}
      </div>
      {more ? (
        <div
          style={{ width: "fit-content", margin: "auto" }}
          onClick={() => {
            setIndex(index + 1);
          }}
        >
          {load2 ? <div className=" loader"></div> : ""}
          <BtnAdmin title={"More Data"} />
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div className=" container fs-5 fw-medium  my-4">
      Now , No product in this is list
      <Link to={"/"}>
        <div
          className=" text-white py-2 px-4 m-auto mt-3"
          style={{ background: "#ff0055", width: "fit-content" }}
        >
          Continue
        </div>
      </Link>
    </div>
  ); */
/* import { useContext, useEffect, useMemo, useState } from "react";




export default function PageProduct() {
  const {dataCate}=useContext(ContextPro);
  const location = useLocation();
  const [dataProduct, setDataProduct] = useState([]);
  const [key ,setKey]=useState([])
  const [value,setValue]=useState({})
  const path=useParams();
  const [loaded,setloader]=useState(true)


console.log(dataCate);

  useEffect(() => {console.log(dataCate);
//setIdCate(dataCate?.map(e=>e?.name==path.id))
   // localStorage.setItem('state',JSON.stringify(location.state.id))
    //console.log( JSON.parse(localStorage.getItem('state')));
    setloader(true)
 
 
   
    urlApiGet(adminPanelAPIs.Product.getProductURL, {
      
      categoryId:location.state===null?dataCate?.find(e=>e.name===path.id)?.id :location.state?.id //idCate //JSON.parse(localStorage.getItem('state'))
    }).then((e) => {
    
      
      setDataProduct(e);
      setKey((prev)=>{
       const data =e?.map(e=>  e.productMoreInfos?.map(t=>t.key ))
        return [...new Set([...data].flat(1))]
      })
      setValue((prev)=>{
        const data =e?.map(e=>  e.productMoreInfos?.map(t=>t )).flat(1)
        const dataKey =[...new Set([...e?.map(e=>  e.productMoreInfos?.map(t=>t.key ))].flat(1))]
        const value={};
        dataKey?.map(e=>{

          value[e]=data?.filter((t)=>{
           return e==t.key})
           value[e]= [...new Set(value[e]?.map(e=>e.value
           ))]
          
         }) 
       
        
         //return [...new Set([...data].flat(1))]
         return value
       })
       setloader(false)
    });

  }, [location,path]);



 
  return (
   loaded? <Loader/>:<div className="all-body">
   <BreadCrumb />
   <h3 className="px-3">Special Offers</h3>
   <div className="content-body ">
     {detectMob() ? (
       <CanvasFilter>
         <FilterBody dataFilter={value} title={key} />
       </CanvasFilter>
     ) : (
       <FilterBody dataFilter={value} title={key}/>
     )}

     <Products data={dataProduct} />

   </div>
 </div>
  );
}
 */
