import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

import CartProduct from "../page_offer/Cartproduct/CartProduct";
import SwiperView from "./SwiperView";
import SwiperViewProduct from "./SwiperViewProduct";
import SwiperViewProduct_Item from "./SwiperViewProduct_Item";
import { ContextPro } from "../../App";
import BarLink from "../footer/BarLink";

import { Admin, User } from "../../utils/AdminUser";
import { detectMob } from "../../utils/utilsFuc";

export default function Home(props) {
  const { dataBrand, dataMontoris } = useContext(ContextPro);
  /* 
  useEffect(() => {
    console.log(props);
    fetch("http://elaser-001-site1.ltempurl.com/api/Brand/Get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((e) => {
        return e.json();
      })
      .then((e) => {
        console.log(e);
      });
  }, []); */

  return (
    <div className="home">
      <SwiperView />

      <SwiperViewProduct />
      <SwiperViewProduct_Item spaceBetween={15} title="Montoris">
        {dataMontoris?.length &&
          dataMontoris?.map((e) => (
            <SwiperSlide>
              <div className="product-grid me-3">
                <CartProduct
                  name={e.title}
                  price={e.price}
                  description={e.description}
                  outofstock={e.outOfStock}
                  photo={e.productAttchments}
                  brand={e.brandId}
                  id={e.id}
                  keyOfValue={e.productMoreInfos}
                  discount={e.discount}
                  categoryid={e.categoryId}
                />
              </div>
            </SwiperSlide>
          ))}
      </SwiperViewProduct_Item>
      {/* banr */}

      <div className=" container">
        <img
          className="w-100"
          src={require("../photo/All-Builds-Middle-Banner-1320x320.png")}
          alt=""
        />
      </div>
      <SwiperViewProduct_Item title="Brand">
        {dataBrand?.map((e) => (
          <SwiperSlide>
            {" "}
            <Link to={`/product/brand/${e.name}`}>
              <img src={e.photo} alt="" />
            </Link>
          </SwiperSlide>
        ))}
      </SwiperViewProduct_Item>
    </div>
  );
}
