import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tip from "./tip";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

export default function Wish(){
    return(
        <div className="wish-btn">
              <Tip text={'wish'} ><FontAwesomeIcon icon={faHeart} /></Tip>
            </div>
    )
}