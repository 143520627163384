import { Link } from "react-router-dom";

import SwiperComp from "../Commons/SwiperComp";

export default function SwiperView({}) {
  return (
    <div className=" swiper-view">
      <SwiperComp
        spaceBetween={0}
        slidesPerView={1}
        renderedItems={[
          <Link to={"/"}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require('../photo/1 (1).jpg')}
              alt=""
              srcset=""
            />
          </Link>,
          <Link to={"/"}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require('../photo/1 (1).png')}  
                          alt=""
              srcset=""
            />
          </Link>,
          <Link to={"/"}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require('../photo/1 (4).jpg')}              alt=""
              srcset=""
            />
          </Link>,
          <Link to={"/"}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require('../photo/1 (5).jpg')}
              alt=""
              srcset=""
            />
          </Link>,
         
        ]}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={true}
      />
    </div>
  );
}
