import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import ClientOrder from "./ClientOrder";
import Loader from "../../../public_componet/loader";
import BtnAdmin from "../BtnAdmin";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd } from "../../../utils/utilsFuc";

import "../Admin.css";

function PageOrder() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [index, setIndex] = useState(1);
  const [status, setStatus] = useState(null);
  const [more, setMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIndex(1);

    urlApiEd(
      adminPanelAPIs.Order.getOrder,
      {
        pageIndex: 1,
        orderStatus: status,
      },
      {
        "Content-Type": "application/json",
        " Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    )
      .then((e) => {
   

        setLoad(false);
        setData(e.list);
        if (e.totalPages === 1 || e.totalPages === 0) {
          setMore(false);
        } else {
          setMore(true);
        }
      })
      .catch(() => {
        setLoad(false);
      });
  }, [status]);
  useEffect(() => {
    if (index > 1) {
      urlApiEd(
        adminPanelAPIs.Order.getOrder,
        {
          pageIndex: index,
          orderStatus: status,
        },
        {
          "Content-Type": "application/json",
          " Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
      )
        .then((e) => {
     

          setLoad(false);
          setData((prev) => [...prev, ...e.list]);
          if (e.totalPages === 1 || e.totalPages <= index) {
            setMore(false);
          } else {
            setMore(true);
          }
        })
        .catch(() => {
          setLoad(false);
        });
    }
  }, [index]);

  return load ? (
    <Loader></Loader>
  ) : (
    <div className=" admin-user">
      <div className=" position-relative">
        {data?.map((e) => (
          <ClientOrder e={e} setData={setData} />
        ))}
        <div className=" filter-order">
          <div className=" filter-order-container">
            <button
              onClick={(e) => {
                setStatus(null);
              }}
              value={null}
              style={{
                backgroundColor: `${status === null ? "red" : "white"}`,
                color: `${status === null ? "white" : "black"}`,
              }}
            >
              All
            </button>

            <button
              onClick={(e) => {
                setStatus(2);
              }}
              style={{
                backgroundColor: `${status === 2 ? "red" : "white"}`,
                color: `${status === 2 ? "white" : "black"}`,
              }}
            >
              pending
            </button>

            <button
              onClick={(e) => {
                setStatus(1);
              }}
              style={{
                backgroundColor: `${status === 1 ? "red" : "white"}`,
                color: `${status === 1 ? "white" : "black"}`,
              }}
            >
              approved
            </button>

            <button
              onClick={(e) => {
                setStatus(3);
              }}
              style={{
                backgroundColor: `${status === 3 ? "red" : "white"}`,
                color: `${status === 3 ? "white" : "black"}`,
              }}
            >
              rejected
            </button>
          </div>
        </div>

        {more ? (
          <div
            className="m-auto"
            style={{
              width: "fit-content",
            }}
          >
            <BtnAdmin
              onclick={() => {
                setIndex(index + 1);
              }}
              title={"More Data"}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PageOrder;
