import React from "react";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BreadCrumb() {
  return (
    <></>
   
  );
}
 {/* <ul className=" my-3 breadcrumb">
      <li>
        <FontAwesomeIcon icon={faHome} color="#8b8b8b" />
      </li>
      <li>Special Offers</li>
    </ul> */}