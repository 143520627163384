export default function InfoBlock({ children }) {
  return (
    <div className="info-block">
      <div className="icon-info">{children}</div>
      <div className="info-block-content">
        <div className="info-title">Secure Payment Methods</div>
        <div className="info-text">
          Cash on delivery and various payment options and installment plans.
        </div>
      </div>
    </div>
  );
}
