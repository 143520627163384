import { useRef, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";

import {
  faAngleDown,
  faMinus,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Admin } from "../../utils/AdminUser";

function LinksCanvas({ item, link }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  return (
    <div className=" ">
      <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="colls-open "
      >
        <Link
         className="section-item"
         to={`${Admin()?"/Admin":""}/product/category/${item.name}`}
         state={{
           id: item.id,
         }}
          onClick={(e) => {
            !open&&e.preventDefault();
            
          }}
        >
          {item?.name}
        </Link>
        <FontAwesomeIcon
          icon={open ? faMinus : faPlus}
          style={{
            border: "1px solid",
            width: "12px",
            borderRadius: "50%",
            height: "12px",
            padding: "3px",
            color: `${!open ? "" : "green"}`,
          }}
        />
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text" ref={ref}>
          <div className="item-filter px-3">
          {item?.childs?.map((subItem) => {
          return !subItem?.childs?.length ? (
            <li>
              <Link
                className="section-item"
                to={`${Admin()?"/Admin":""}/product/category/${subItem.name.split("/").join("-")}`}
                state={{
                  id: subItem.id,
                }}
              >
                {subItem.name}
              </Link>
            </li>
          ) : (
            <LinksCanvas item={subItem}  />
          );
        })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default LinksCanvas;
