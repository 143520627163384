import React, { useState } from "react";

import BtnAdmin from "../BtnAdmin";

export default function AddSomeInfo({ onclick }) {
  const [components, setComponents] = useState([]);

  const addComponent = () => {
    const newComponent = {
      value: "",
      key: "",
    };
    setComponents([...components, newComponent]);
  };

  const handleInputChange = (id, newValue) => {
    setComponents(
      components.map((component, i) =>
        i === id ? { ...component, value: newValue } : component
      )
    );
  };
  const handleInputChangeKey = (id, newValue) => {
    setComponents(
      components.map((component, i) =>
        i === id ? { ...component, key: newValue } : component
      )
    );
  };
  onclick(components);
  return (
    <div className=" add-info">
      <BtnAdmin onclick={addComponent} title={"Add More Information"} />
      {components.map((component, i) => (
        <div key={i} className=" mt-2">
          <input
            type="text"
            value={component.key}
            placeholder="Key"
            onChange={(e) => handleInputChangeKey(i, e.target.value)}
          />
          <input
            type="text"
            value={component.value}
            placeholder="Value"
            onChange={(e) => handleInputChange(i, e.target.value)}
          />
        </div>
      ))}
    </div>
  );
}
