import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartTable from "./CartTable";
import "./shoppingCart.css";
import CartBottom from "./CartBottom";
import { useContext } from "react";
import { ContextPro } from "../../App";
import { User } from "../../utils/AdminUser";

export default function Page_Cart(){
    const { idcart, SetIdCart } = useContext(ContextPro);
    return(
      
        <div className="all-body">
        <div>
        {/* <ul className=" my-3 breadcrumb container">
            <li>
                <FontAwesomeIcon icon={faHome} color="#8b8b8b" />
            </li>
            <li>
                Special Offers
            </li>
        </ul> */}
        </div>
        <div>
            <div className=" container">
                <h2 className="my-5">Shopping Cart</h2>
                <div className=" container-cart">
                <CartTable data={ JSON.parse(localStorage.getItem('cart'))||idcart }/>
                <CartBottom data={idcart[0]?.totalAmount}/>
                </div>
            </div>
        </div>
        </div>
    )
}

