import { useContext } from "react";

import Stepper from "../page_offer/Cartproduct/Stepper";
import Tip from "../page_offer/Cartproduct/tip";
import { faArrowsRotate, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";

export default function CartTable({ data }) {
  return (
    <div className="res-table">
      <table>
        <thead>
          <tr>
            <td className=" row-t-head">Image</td>
            <td className=" row-t-head">Product Name</td>
            {/*  <td className=" row-t-head">Model</td> */}
            <td className=" row-t-head">Quantity</td>
            <td className=" row-t-head">Unit Price</td>
            <td className=" row-t-head">Total</td>
          </tr>
        </thead>
        <tbody>
          {!localStorage.getItem("cart")
            ? data[0]?.cartProducts?.map((e) => (
                <tr>
                  <td className=" row-t-body">
                    <img src={e.productPhoto} alt="" />
                  </td>
                  <td className=" row-t-body">{e?.productTitle}</td>
                  {/*  <td className=" row-t-body">ASUS-KB-TUF-GAMINGK1</td> */}
                  <td className=" row-t-body td-quantity">
                    {/*  <div className="group-td-count">
                <Stepper/>
                <Tip text={'Updata'}><FontAwesomeIcon icon={faArrowsRotate  } /></Tip>
                <Tip text={'Remove'}><FontAwesomeIcon icon={faXmark} /></Tip>
                </div> */}
                    {e.count}
                  </td>
                  <td className=" row-t-body">EGP {e.productPrice}</td>
                  <td className=" row-t-body">EGP {e.totalAmount}</td>
                </tr>
              ))
            : data.map((e) => (
                <tr>
                  <td className=" row-t-body">
                    <img src={e?.productPhoto[0]?.photo} alt="" />
                  </td>
                  <td className=" row-t-body">{e?.productTitle}</td>
                  {/*  <td className=" row-t-body">ASUS-KB-TUF-GAMINGK1</td> */}
                  <td className=" row-t-body td-quantity">
                    {/*  <div className="group-td-count">
                <Stepper/>
                <Tip text={'Updata'}><FontAwesomeIcon icon={faArrowsRotate  } /></Tip>
                <Tip text={'Remove'}><FontAwesomeIcon icon={faXmark} /></Tip>
                </div> */}
                    {e.count}
                  </td>
                  <td className=" row-t-body">EGP {e.productPrice}</td>
                  <td className=" row-t-body">EGP {e.totalAmount}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
