import { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";

import { ContextPro } from "../../App";
import BtnAdmin from "../Admin/BtnAdmin";

import { adminPanelAPIs } from "../../utils/config";
import { urlApiEd } from "../../utils/utilsFuc";

export default function TableInfo({ title, data }) {
  const { idUser } = useContext(ContextPro);
  const location = useLocation();
  const dataTab = data;
  const [showDataTab, setShowDataTab] = useState({});
  const description = data?.description;

  useEffect(() => {
    setShowDataTab(data?.productMoreInfos);
  }, [data]);

  return (
    <Tabs
      defaultActiveKey="profile"
      id="tab-rela"
      className="mb-3 tab-relation"
    >
      {showDataTab?.length ? (
        <Tab onSelect={true} eventKey="profile" title="Information">
          <div className="table-info">
            <table>
              <thead>
                <tr>
                  <td colSpan={3}>GENERAl</td>
                </tr>
              </thead>
              <tbody>
                {showDataTab?.map((e) => (
                  <tr>
                    <td>{e.key}</td>
                    <td>{e.value}</td>
                    {localStorage.getItem("token") && idUser?.roles?.length ? (
                      <BtnAdmin
                        title={"Delete"}
                        onclick={() => {
                          urlApiEd(
                            adminPanelAPIs.ProductMoreInfo.deleteProductMoreInfoURL(
                              e.id
                            )
                          ).then((t) => {
                            setShowDataTab((prev) =>
                              prev?.filter((d) => d.id !== t.id)
                            );
                          });
                        }}
                      />
                    ):''}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
      ) : (
        ""
      )}
      {description ? (
        <Tab onSelect={true} eventKey="home" title="Home">
          {description}
        </Tab>
      ) : (
        ""
      )}
    </Tabs>
  );
}
