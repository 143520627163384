import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContextPro } from '../../../App'
import BreadCrumb from '../../BreadCrumb'
import BtnAdmin from '../BtnAdmin'
import ChooseImg from '../ChooseImg'
import InputComponent from '../InputComponent'

import { adminPanelAPIs } from '../../../utils/config'
import { urlApiEd} from '../../../utils/utilsFuc'

export default function AddCategory() {
  const { dataCate, setDataCate } = useContext(ContextPro);
    const navigate = useNavigate();
    const [addData, setAddData] = useState({
        name: "",
        description: "",
        parentId: null
    })

    function addNewCategory(){
        // start loader

        // - post cat data to server
        urlApiEd(adminPanelAPIs.Categotry.addCategoriesURL, {
                            name:addData.name,
                            description: addData.description,
                            parentId: addData.parentId
                        }).then(res=> {
                        
                            // update cat data 
                            setDataCate(prev=>[...prev, res])
                            // stop loader
                            // show notification to user 
                            // go back
                            navigate(-1);

                        }).catch(err=>{

                        });
    }

    
    return (
        <div className=" container">
            <BreadCrumb />

            <div className=" brand-data">
                <InputComponent title={"Name"}>
                    <input type="text" value={addData.name} onChange={(e) => { setAddData({ ...addData,name: e.target.value }) }} /></InputComponent>
                <InputComponent title={'ParentId'} >
                    <select  onChange={(e)=>{setAddData({...addData,parentId:e.target.value})}}>
                        <option value={null}>no parent</option>
                        {dataCate?.map(e => (
                            <option value={e.id}>{e.name}</option> 
                        ))}
                    </select>
                </InputComponent>
                <InputComponent title={'ParentId'} >
                    <textarea value={addData.description} onChange={(e)=>{
                        setAddData({...addData,description:e.target.value})
                    }}></textarea>
                </InputComponent>
            </div>
            <div className=" d-flex gap-4">
                {/* <Link to={'/'}> */}
                    <BtnAdmin title={"Creat"} bgcolor="green" onclick={addNewCategory} />
                    {/* </Link> */}
                <BtnAdmin title={"Back to List"} bgcolor="blue" />
            </div>
        </div>
    )
}
