import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CanvasFilter from "../page_offer/CanvasFilter";
import FilterBody from "../page_offer/Cartproduct/FilterBody";
import Products from "../page_offer/Cartproduct/Products";
import { ContextPro } from "../../App";
import BtnAdmin from "../Admin/BtnAdmin";
import BreadCrumb from "../BreadCrumb";
import CanvasInCenter from "../Commons/CanvasInCenter";
import Loader from "../loader";

import { adminPanelAPIs } from "../../utils/config";
import { detectMob, urlApiGet } from "../../utils/utilsFuc";

export default function PageSearch() {
  const [pageSize, setPageSize] = useState(10);
  const { dataCate, dataBrand } = useContext(ContextPro);
  const path = useParams();
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [indexPagesApi, setIndexPagesApi] = useState(1);
  const [indexPagesApiSearch, setIndexPagesApiSearch] = useState(1);
  const [indexPage, setIndexPage] = useState(1);
  const [indexSearch, setIndexSearch] = useState(1);
  const location = useLocation();
  const [more, setMore] = useState(true);
  const [load2, setload2] = useState(false);
  const [load3, setload3] = useState(false);
  const [load, setload] = useState(true);
  const [error, setError] = useState(true);
  const [filterObj, setFilterObj] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setFilterObj({});
    setDataProduct([]);
    setIndexPage(1);
    setIndexPagesApiSearch(1);
    setMore(true);
    setload(true);

    const requestPayload = { title: path.search, pageSize: pageSize };
    urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
      (e) => {
        e?.list?.length && setDataProduct([...e.list]);
        setIndexPagesApi(e.totalPages);
        //!e.length && setDataProduct([]);
        (e?.totalPages === 1 || e?.totalPages === 0) && setMore(false);

        setload(false);
      }
    );
  }, [path.search, pageSize]);

  useEffect(() => {
    if (indexPage > 1 && dataFilter.length === 0) {
      setload2(true);
      const requestPayload = {
        title: path.search,
        pageIndex: indexPage,
        pageSize: pageSize,
      };

      urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
        (e) => {
          setload2(false);

          e?.list?.length && setDataProduct([...dataProduct, ...e.list]);

          e?.totalPages <= indexPage && setMore(false);
          setload(false);
        }
      );
    }
  }, [indexPage]);
  const hanldeFilterProducts = (filterParams) => {
    const { isClear = false, name, value } = filterParams;
    // isClear = true ---> get all products
    // else ---> use filter
    if (isClear) {
      indexPage < indexPagesApi && setMore(true);

      setFilterObj({});
      setDataFilter([]);
      /*  const requestPayload =
          path.type === "brand"
            ? { brandId: id, pageIndex: index }
            : path.type === "category"
            ? {
                categoryId: id,
                pageIndex: 1,
              }
            : navigate("/");
        urlApiGet(adminPanelAPIs.Product.getProductURL, requestPayload).then(
          (e) => {
            setload2(false);
            console.log(e);
            setDataProduct(e);
            !e.length && setMore(false);
  
            setload(false);
          }
        ); */
      // fetch("", )
    } else {
      // filter obj ===> minPrice = 100
      // current I made change in maxPrice to be 20000
      let filterObjClone = { ...filterObj, [name]: value };
      if (!value) {
        delete filterObjClone[name];
      }
      setFilterObj(filterObjClone);

      setMore(true);
      setIndexSearch(1);
      // fetch("", filterObjClone)
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(filterObj).length !== 0) {
        setMore(true);

        indexSearch === 1 && setload3(true);
        const requestPayload = {
          title: path.search,
          pageIndex: indexSearch,
          pageSize: pageSize,
        };
        urlApiGet(adminPanelAPIs.Product.getProductURL, {
          ...requestPayload,
          ...filterObj,
        })
          .then((e) => {
            setload3(false);

            setIndexPagesApiSearch(e.totalPages);
            indexSearch === 1
              ? setDataFilter(e.list)
              : setDataFilter((prev) => [...prev, ...e.list]);
            e?.totalPages <= indexSearch && setMore(false);
            (e?.totalPages === 1 || e?.totalPages === 0) && setMore(false);
            !e?.list?.length && setError(true);
          })
          .catch(() => {
            setload2(false);
          });
      } else {
        setDataFilter([]);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterObj, indexSearch]);

  return (
    <div className="all-body container">
      <BreadCrumb />
      <h3 className="px-3 my-4 fs-3">{path.id}</h3>
      <div className="content-body ">
        {detectMob() ? (
          <CanvasFilter>
            <FilterBody
              filterObj={filterObj}
              hanldeFilterProducts={hanldeFilterProducts}
              dataFilter={dataFilter || dataProduct}
            />
          </CanvasFilter>
        ) : (
          <FilterBody
            filterObj={filterObj}
            hanldeFilterProducts={hanldeFilterProducts}
            dataFilter={dataFilter || dataProduct}
          />
        )}
        {load ? (
          <Loader></Loader>
        ) : indexPagesApiSearch === 0 && Object.keys(filterObj).length ? (
          ""
        ) : (
          <Products
            data={dataFilter.length ? dataFilter : dataProduct}
            setdata={setDataProduct}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        )}
      </div>
      {more ? (
        <div
          style={{ width: "fit-content", margin: "auto" }}
          onClick={() => {
            if (Object.keys(filterObj).length) {
              setload2(true);
              setIndexSearch(indexSearch + 1);
            } else {
              setload2(true);
              setIndexPage(indexPage + 1);
            }
          }}
        >
          {load2 ? <div className=" loader"></div> : ""}
          <BtnAdmin title={"More Data"} />
        </div>
      ) : (
        ""
      )}
      {load3 ? <Loader></Loader> : ""}
      {
        <CanvasInCenter showcenter={error} setError={setError}>
          <div className=" my-5">
            No products with these specifications available.
          </div>
        </CanvasInCenter>
      }
    </div>
  );
}
