import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoryList from "./CategoriesList";
import Links from "./Links";
import MainLink from "./MainLink";
import { ContextPro } from "../../App";

import { Admin, User } from "../../utils/AdminUser";

export default function BottomBar({ data }) {
  const { dataCate } = useContext(ContextPro);
  const [posY, setPosY] = useState(0);

  const [renderData, setRenderData] = useState([]);
  function setChildsToCat(allcategories, levelZeroCat) {
    levelZeroCat.forEach((item) => {
      let itemId = item.id;
      let childs = allcategories.filter(
        (category) => category.parentId === itemId
      );
      if (childs.length) {
        item.childs = childs;
        setChildsToCat(allcategories, childs);
      }
    });
  }

  useEffect(() => {
    if (dataCate?.length) {
      let levelZeroCat = dataCate?.filter((item) => !item.parentId);
      setChildsToCat(dataCate || [], levelZeroCat || []);
      setRenderData(levelZeroCat);
    }
  }, [dataCate]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setPosY(window.scrollY);
    });
  }, []);

  return (
    <div
      className={` m-0 shadow-sm pos-fixed-bar ${
        posY > 50 ? "pos-fixed-bar-transition" : ""
      }`}
    >
      <div className="  d-flex align-items-center justify-content-between container ">
        <ul className=" d-flex m-0">
          <CategoryList key={"cate-list"} renderData={renderData} />
          {data
            ? Object.values(data).map((e) => (
                <Links title={e} Linkto={"/Admin/" + e} />
              ))
            : ""}
          {(User() || !User()) &&
            !Admin() &&
            renderData?.map((e) => {
              const checkChild =
                e?.childs?.length &&
                e?.childs?.map((t) => (t?.childs?.length ? false : true));
              if (
                checkChild?.reduce((prev, current) => prev + current, false) ===
                  checkChild?.length &&
                e["childs"] !== undefined
              ) {
                return (
                  <Links
                    title={e.name}
                    Linkto={`/product/category/${e.name.split("/").join("-")}`}
                    subTitle={e.childs}
                  />
                );
              }
            })}
        </ul>
        {Admin() ? (
          ""
        ) : (
          <div className="icon-tel d-flex gap-2">
            <div>
              <FontAwesomeIcon icon={faPhoneVolume} />
              01007446988
            </div>
          </div>
        )}
      </div>
    </div>
  );

}

/*  */
