import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebookF,
  faInstagram,
  faTelegram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMailBulk,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarLink from "./BarLink";
import Row2F from "./Row2F";

import { detectMob } from "../../utils/utilsFuc";

export default function Footer() {
  library.add(fab);
  return (
    <div className={"all-rows"}>
      {detectMob() ? <BarLink /> : <></>}
      <div className="row-contact ">
        <div className=" container px-3">
          <div className="row-1">
            <div>
              <h3>We're Always Here To Help</h3>
              <span>Reach out to us through any of these support channels</span>
            </div>
            <div className="contact">
              <div>
                <FontAwesomeIcon icon={faPhoneVolume} />

                <span>
                  <h5>phone number</h5>
                  <div>01238745021</div>
                </span>
              </div>
              <div>
                {" "}
                <FontAwesomeIcon icon={faEnvelope} />
                <span>
                  <h5>phone number</h5>
                  <div>01238745021</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-cate ">
        <div className=" container px-5">
          <div className="row-2">
            <Row2F title={"Customer Services"}>
              <span className=" d-block">
                <Link>Warranty policy</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
            </Row2F>
            <Row2F title={"Customer Services"}>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
            </Row2F>
            <Row2F title={"Customer Services"}>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
              <span className=" d-block">
                <Link>Ahw Sotre</Link>
              </span>
            </Row2F>
            <Row2F title={"social meida"}>
              <Link
                to={"https://www.facebook.com/share/29bqnFHxkhoVVeig/"}
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} color="white" size="2x" />
              </Link>
              <Link
                to={
                  "https://www.instagram.com/elasrcompany/profilecard/?igsh=MWsyb2hxa2d1MzRleg=="
                }
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} color="white" size="2x" />
              </Link>
              <Link
                to={"https://www.facebook.com/share/29bqnFHxkhoVVeig/"}
                target="_blank"
              >
                <FontAwesomeIcon icon={faWhatsapp} color="white" size="2x" />
              </Link>
              <Link to={"https://t.me/alaser_computer"} target="_blank">
                <FontAwesomeIcon icon={faTelegram} color="white" size="2x" />
              </Link>
            </Row2F>
            <Row2F title={"contact us"}>
              <form action="" className=" d-flex submit ">
                <div>
                  <label>
                    Your Name <span className="text-danger">*</span>
                  </label>
                  <input type="text" />
                </div>
                <div>
                  {" "}
                  <label>
                    Company <span className="text-danger">*</span>
                  </label>
                  <input type="text" />
                </div>
                <div>
                  {" "}
                  <label>
                    Your Email <span className="text-danger">*</span>
                  </label>
                  <input type="text" />
                </div>
                <div>
                  <label>
                    Massage <span className="text-danger">*</span>
                  </label>
                  <textarea name="" id="" style={{ resize: "none" }}></textarea>
                </div>
                <div>
                  <button
                    className="btn-submit"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Row2F>
          </div>
        </div>
      </div>
    </div>
  );
}
