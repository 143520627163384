

const SERVER_URL = "https://elaser-001-site1.ltempurl.com/";
export const adminPanelAPIs = {
    Categotry: {
        getCategoriesURL: SERVER_URL + "api/category/Get",
        addCategoriesURL: SERVER_URL + "api/category/Add",
        editCategoriesURL: SERVER_URL + "api/category/Edit",
        deleteCategoriesURL: (id) => SERVER_URL + `api/Category/Delete/${id}`
    },
    Brand: {
        getBrandURL: SERVER_URL + "api/Brand/Get",
        addBrandURL: SERVER_URL + "api/Brand/Add",
        editBrandURL: SERVER_URL + "api/Brand/Edit",
        deleteBrandURL: (id) => SERVER_URL + `api/Brand/Delete/` + id
    },
    Product: {
        getProductURL: SERVER_URL + "api/Product/Get",
        addProductURL: SERVER_URL + "api/Product/Add",
        editProductURL: SERVER_URL + "api/Product/Edit",
        deleteProductURL: (id) => SERVER_URL + `api/Product/Delete/`+id
    },
    ProductAttchment: {
        getProductAttchmentURL: SERVER_URL + "api/ProductAttchment/Get",
        addProductAttchmentURL: SERVER_URL + "api/ProductAttchment/Add",
        editProductAttchmentURL: SERVER_URL + "api/ProductAttchment/Edit",
        deleteCategoriesURL: (id) => SERVER_URL + `api/ProductAttchment/Delete/`+id
    },
    ProductMoreInfo: {
        getProductMoreInfoURL: SERVER_URL + "api/ProductMoreInfo/Get",
        addProductMoreInfoURL: SERVER_URL + "api/ProductMoreInfo/Add",
        editProductMoreInfoURL: SERVER_URL + "api/ProductMoreInfo/Edit",
        deleteProductMoreInfoURL: (id) => SERVER_URL + `api/ProductMoreInfo/Delete/`+id
    },
    Cart: {
        getCart: SERVER_URL + "api/Cart/Get",
        checkOut: (id) => SERVER_URL + `api/Cart/Checkout?Address=`+id

    },
    Order: {
        getOrder: SERVER_URL + "api/Order/Get",
        actionOrder: (id,status)=> SERVER_URL + `api/Order/${id}/Action/${status}`,
        deleteOrder: (id)=>  SERVER_URL + `api/Order/Delete/${id}`
    },
    cartAdd: {
        addCart: SERVER_URL + "api/CartProduct/Add",
     
        deleteCart: (id) => SERVER_URL + `api/CartProduct/Delete/`+id
    },
    Account: {
        userRegister: SERVER_URL + "api/Auth/Register",
        userLogin: SERVER_URL + "api/Auth/Login",
        userCurrent: SERVER_URL + "api/Auth/GetCurrentUser",

    },

}
