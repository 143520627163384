import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CollsCart from "./CollsCart";
import SelectCartRegion from "./SelectCartRegion";
import { ContextPro } from "../../App";
import BtnAdmin from "../Admin/BtnAdmin";
import CanvasInCenter from "../Commons/CanvasInCenter";

import { tok, User } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiEd } from "../../utils/utilsFuc";

export default function CartBottom({ data }) {
  const { SetIdCart, idcart } = useContext(ContextPro);
  const [address, setAddress] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  return (
    <div className=" cart-bottom">
      {/* <CollsCart text={"Use Coupon Code"}>
        <div className="coupon">
          <label htmlFor="input-cart-coupon">Enter your coupon here</label>
          <div className="input-cart-coupon  d-flex flex-wrap gap-3 mt-2">
            <input
              className="  col-12  "
              type="text"
              placeholder="Enter your coupon"
            />
            <button className="  col-12  ">Apply Coupon</button>
          </div>
        </div>
      </CollsCart>
      <CollsCart text={"Estimate Shipping & Taxes"}>
        <div className=" region">
          <label htmlFor="input-cart-region" className=" mb-2">
            Enter your destination to get a shipping estimate.
          </label>
          <SelectCartRegion text={"Country"}>
            <option value="egypt">egypt</option>
            <option value="egypt">egypt</option>
            <option value="egypt">egypt</option>
          </SelectCartRegion>
          <SelectCartRegion text={"Region/State"}>
            <option value="egypt">egypt</option>
            <option value="egypt">egypt</option>
            <option value="egypt">egypt</option>
          </SelectCartRegion>
          <div>
            <label>Enter your coupon here</label>
            <div className="input-cart-coupon  d-flex flex-wrap gap-3 mt-2">
              <input
                className="  col-12  "
                type="text"
                placeholder="Enter your coupon"
              />
              <button className="  col-3  ">Get Quotes</button>
            </div>
          </div>
        </div>
      </CollsCart>  */}
      <CollsCart text={"Enter address to checkout"}>
        <div className=" region">
          <div>
            <label>Enter your address</label>
            <div className="input-cart-coupon  d-flex flex-wrap gap-3 mt-2">
              <input
                value={address}
                className="  col-12  "
                type="text"
                placeholder="Enter your coupon"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </CollsCart>

      <div className=" total-cart">
        <div className="price-total">
          {/* <div className="sub-total">
            {" "}
            <span className=" me-3">Sub-Total :</span> EGP5,3544
          </div>
          <div className="discount">
            <span className=" me-3">VAT(14%) :</span>EGP5,3544
          </div> */}
          <div className="total">
            <span className=" me-3">Total :</span>EGP {data}
          </div>
        </div>
        <div className="btns-cart">
          <button
            className="btn-view"
            onClick={() => {
              navigate("/");
            }}
          >
            continue shopping
          </button>
          <button
            className="btn-check-out"
            onClick={() => {
              urlApiEd(
                adminPanelAPIs.Cart.checkOut(address),
                {},
                {
                  "Content-Type": "application/json",
                  " Authorization": `Bearer ${tok()}`,
                }
              )
                .then((e) => {
                  SetIdCart([]);
                  setAddress("");
                })
                .catch((e) => setError(true));
            }}
          >
            check out
          </button>
          {User() ? (
            <button
              className="btn-check-out"
              onClick={() => {
                navigate("/MyOrder");
              }}
            >
              Orders
            </button>
          ) : (
            ""
          )}

          <CanvasInCenter showcenter={error} setError={setError}>
            {User() ? (
              <div className=" my-5">pealse Enter The Address</div>
            ) : (
              <Link to={"/login"}>
                <div className=" my-3 text-center">
                  <div>please login then order</div>
                  <BtnAdmin title={"Login"} />
                </div>
              </Link>
            )}
          </CanvasInCenter>
        </div>
      </div>
    </div>
  );
}
