import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ContextPro } from "../../../App";

import "./cart.css";

export default function NotificationAdd() {
  const { notificationAdd, setNotificationAdd } = useContext(ContextPro);
  const [visibleItems, setVisibleItems] = useState([]);
 

  useEffect(() => {
    if (notificationAdd) {
      const newItem = notificationAdd;
      setVisibleItems([...visibleItems, newItem]);
 

      setTimeout(() => {
        
        setVisibleItems((prevVisibleItems) =>
          prevVisibleItems.filter((item) => item !== newItem)
        );
      }, 3000);
      setNotificationAdd();
    }
  }, [notificationAdd]);
 
  return (
    <div className=" notification-add ">
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "0",
          padding: "20px 0",
        }}
      >
        {visibleItems.length
          ? visibleItems?.map((item, index) => (
              <div
                style={{
                  width: "360px",
                  maxWidth: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: "2.5px",
                  padding: "10px 20px",
                  pointerEvents: "all",
                  marginBottom: "30px",
                  border: ".5px solid #676765ba",
                }}
              >
                <div className=" d-flex">
                  <span
                    style={{
                      display: "block",
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                    }}
                  >
                    <img
                      className=" w-100"
                      src={item?.productPhoto[0]?.photo}
                      alt=""
                      srcset=""
                    />
                  </span>
                  <div>
                    <h6>{item?.productTitle}</h6>
                    <small>success:have added</small>
                    <Link
                      to={""}
                      className=" d-block text-decoration-underline"
                      style={{ color: "#FF0022" }}
                    >
                      {item?.productTitle}
                    </Link>
                    <small className=" text-decoration-none">
                      Black to your
                    </small>
                    <Link
                      to={"/cart"}
                      className="text-decoration-underline"
                      style={{ color: "#FF0022" }}
                    >
                      shopping cart!
                    </Link>
                  </div>
                </div>
                <div></div>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}
/* import React, { useState, useEffect } from 'react';


const ItemList = ({ items }) => {
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    items.forEach((item, index) => {
      setTimeout(() => {

        setVisibleItems((prev) => [...prev, item]);
        setTimeout(() => {
          setVisibleItems((prev) => prev.filter((i) => i !== item));
        }, 2000); // Time after which the item disappears
      }, index * 500); // Time interval between items appearing
    });
  }, [items]);

  return (
    <div>
      {visibleItems.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

const App = () => {
  const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  return (
    <div>
      <h1>Items</h1>
      <ItemList items={items} />
    </div>
  );
};

export default App; */
/* import React, { useState } from 'react';

const App = () => {
  const [items, setItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  const addItem = () => {
    const newItem = `Item ${items.length + 1}`;
    setItems([...items, newItem]);
    setVisibleItems([...visibleItems, newItem]);

    // إخفاء العنصر تلقائيًا بعد 3 ثوانٍ
    setTimeout(() => {
      setVisibleItems((prevVisibleItems) => prevVisibleItems.filter(item => item !== newItem));
    }, 3000);
  };

  return (
    <div>
      <button onClick={addItem}>Add Item</button>
      <ul>
        {visibleItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default App;
 */
