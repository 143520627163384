import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  faAngleDown,
  faAngleUp,
  faCartArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCart from "./AddCart";
import Stepper from "./Stepper";
import SwiperViewQuick from "./SwiperViewQuick";
import Tip from "./tip";
import Wish from "./Wish";
import { ContextPro } from "../../../App";
import BtnAdmin from "../../Admin/BtnAdmin";
import CanvasInCenter from "../../Commons/CanvasInCenter";
import Loader from "../../loader";
import InfoProduct from "../../production_info/InfoProduct";

import { Admin, tok } from "../../../utils/AdminUser";
import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd, urlApiGet } from "../../../utils/utilsFuc";

export default function CartProduct({
  photo = [],
  name,
  brand,
  description,
  price,
  outofstock = true,
  id,
  keyOfValue,
  discount,
  categoryid,
  setdata,
  data,
}) {
  const { dataBrand, idUser } = useContext(ContextPro);
  const path = useParams();
  const [error, setError] = useState(false);
  const [count, setCount] = useState(1);
  const [load, setload] = useState(false);
  function getCount(counts) {
    setCount(counts);
  }
  //onClick -->  navigate to product page + ---> setSelectedProduct
  return (
    <div className="product-cart">
      {load ? <Loader /> : ""}
      <div className="image">
        {discount !== 0 && <div className=" discount">-{discount}%</div>}
        <div className="icon-zoom">
          <CanvasInCenter
            rendertag={
              <Tip text={"Quickview"}>
                {" "}
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
              </Tip>
            }
          >
            <div className="container-swiper d-flex">
              <SwiperViewQuick photo={photo} />
              <InfoProduct
                name={name}
                brand={brand}
                out={outofstock}
                price={price}
                id={id}
                photo={photo}
                categoryid={categoryid}
                description={description}
              />
            </div>
          </CanvasInCenter>
        </div>
        <Link
          to={
            path.type
              ? `${Admin() ? "/Admin" : ""}/product/${path.type}/${
                  path.id
                }/${id}`
              : `${Admin()?(`Admin/name/` + name.split(' ').join('-') + "/" + id):(`/name/` + name.split(' ').join('-') + "/" + id)}`
          }
          state={{
            photo: photo,
            name: name,
            brand: brand,
            out: outofstock,
            price: price,
            id: id,
            keyOfValue: keyOfValue,
            discount: discount,
            description: description,
          }}
          className="image-link"
        >
          <div>
            {photo?.length == 1 ? (
              <img className=" one-image " src={photo[0]?.photo} alt="" />
            ) : (
              <>
                {" "}
                <img className="frist-img" src={photo[0]?.photo} alt="" />
                <img className="second-img" src={photo[1]?.photo} alt="" />
              </>
            )}
          </div>
        </Link>
      </div>
      <div className="caption">
        <div className="stats">
          <span className="stat-1">
            <span className="stat-lable">Brand : </span>{" "}
            <span>
              {dataBrand?.map((e) => (e.id == brand ? <a>{e?.name}</a> : ""))}
            </span>
          </span>
          <span className="stat-2">
            {/* <span className="stat-lable">Model : </span>{" "}
            <span>ASUS-MNT-VA24EHE</span> */}
          </span>
        </div>
        <div className="name fs-6  fw-normal ">
          <Link
            to={
              path.type
                ? `${Admin() ? "/Admin" : ""}/product/${path.type}/${
                    path.id
                  }/${id}`
                : `${Admin()?(`Admin/name/` + name.split(' ').join('-') + "/" + id):(`/name/` + name.split(' ').join('-') + "/" + id)}`
            }
            state={{
              photo: photo,
              name: name,
              brand: brand,
              out: outofstock,
              price: price,
              id: id,
              keyOfValue: keyOfValue,
              discount: discount,
              description: description,
            }}
          >
            {name}
          </Link>
        </div>
        <div className="description">{description}</div>
        <div className="price">
          {discount !== 0 && (
            <>
              <span className=" after-discount fs-5 fw-bold">
                EGP {((100 - discount) / 100) * price}
              </span>
              <span className=" before-discount fs-6 ">EGP {price}</span>
            </>
          )}
          {discount === 0 && <div className="price-new">EGP {price}</div>}
          <span className="price-tax">Price including Taxes</span>
        </div>
        <div className="wrap-btn">
          <div className="group-btn">
            {localStorage.getItem("token") && idUser?.roles?.length ? (
              <>
                <Link
                  to={"/Admin/product/Edit"}
                  state={{
                    name: name,
                    brand: brand,
                    out: outofstock,
                    price: price,
                    id: id,
                    keyOfValue: keyOfValue,
                    description: description,
                    categoryid: categoryid,
                  }}
                >
                  <BtnAdmin title={"Edit "} />
                </Link>{" "}
                <BtnAdmin
                  bgcolor="red"
                  title={"Delete"}
                  onclick={() => {
                    setload(true);
                    urlApiEd(adminPanelAPIs.Product.deleteProductURL(id))
                      .then((e) => {
                     
                        setload(false);
                        const dataClone = data?.filter((t) => t.id !== e.id);
                   

                        setdata(dataClone);
                      })
                      .catch((e) => {
                        setload(false);
                        setError(true);
                      });
                  }}
                />
              </>
            ) : outofstock ? (
              <button
                disabled
                style={{
                  background: "#f10050b8",
                  color: "white",
                  padding: "10px 20px ",
                  border: "none",
                  width:"100%",
                  cursor:"not-allowed"
                }}
              >
                out of Stock
              </button>
            ) : (
              <>
                {" "}
                <div className="cart-btn">
                  <Stepper onchange={getCount} />
                  <AddCart
                    id={id}
                    count={count}
                    productPhoto={photo}
                    productPrice={price}
                    totalAmount={price * count}
                    productTitle={name}
                  />
                </div>
               
              </>
            )} <Wish />
          </div>
          <CanvasInCenter setError={setError} showcenter={error}>
            <div className="mt-4">
              Product is finding in list of order So can't delete{" "}
            </div>
          </CanvasInCenter>
        </div>
      </div>
    </div>
  );
}
