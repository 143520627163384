import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ContextPro } from "../../../App";
import BreadCrumb from "../../BreadCrumb";
import BtnAdmin from "../BtnAdmin";
import ChooseImg from "../ChooseImg";
import InputComponent from "../InputComponent";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd} from "../../../utils/utilsFuc";

export default function EditCategory() {
  const { adminList, dataCate, setDataCate } = useContext(ContextPro);
  const navigate = useNavigate();
  const location = useLocation();
  const [addData, setAddData] = useState({
    id: location?.state?.id,
    name: location?.state?.name,
    description: location?.state?.descr,
    parentId: location?.state?.parentId,
  });


  const EditCategory = () => {
    urlApiEd(adminPanelAPIs.Categotry.editCategoriesURL, {
      id: addData.id,
      name: addData.name,
      description: addData.description,
      parentId: addData.parentId ? addData.parentId : null,
    }).then((res) => {
      // update cat data
      setDataCate((prev) => {
        let prevClone = [...prev];
        let editedCatIndex = prevClone.findIndex((item) => item.id === res.id);
        prevClone.splice(editedCatIndex, 1, res);
        return prevClone;
      });
      // stop loader
      // show notification to user
      // go back
      navigate(-1);
    });
  };
  return (
    <div className=" container">
      <BreadCrumb />

      <div className=" brand-data">
        <InputComponent title={"Name"}>
          <input
            type="text"
            value={addData.name}
            onChange={(e) => {
              setAddData({ ...addData, name: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"ParentId"}>
          <select
            value={addData.parentId}
            onChange={(e) => {
              setAddData({ ...addData, parentId: e.target.value });
            }}
          >
            <option value={null}></option>
            {dataCate?.map(
              (e) =>
                location.state.id !== e.parentId && (
                  <option value={e.id}>{e.name}</option>
                )
            )}
          </select>
        </InputComponent>
        <InputComponent title={"Description"}>
          <textarea
            value={addData.description}
            onChange={(e) => {
              setAddData({ ...addData, description: e.target.value });
            }}
          ></textarea>
        </InputComponent>
      </div>
      <div className=" d-flex gap-4">
        <BtnAdmin title={"Save"} bgcolor="green" onclick={EditCategory} />
        <BtnAdmin
          title={"Back to List"}
          bgcolor="blue"
          onclick={() => navigate(-1)}
        />
      </div>
    </div>
  );
}
