import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function CanvasFilter({ children }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (


        <>

            <div className="btn-filter" onClick={handleShow}>
                <FontAwesomeIcon icon={faFilter} className=' me-3' />
                Filter Products
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='mobile-filter'>
                        {children}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>

    )
}
