import React, { useEffect, useState } from "react";

export default function ChooseImg({ onclick, multiImg = true }) {
  const [base64Files, setBase64Files] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(convertToBase64(files[i]));
    }

    Promise.all(promises).then((base64Files) => {
      //setBase64Files((prevFiles) => [...prevFiles, ...base64Files]);
      setBase64Files([...base64Files]);

    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ name: file.name, base64: reader.result });

      reader.onerror = (error) => reject(error);
    });
  };

  const handleDelete = (index) => {

    setBase64Files((prevFiles) => prevFiles.filter((_, i) => i !== index));
    index === 0 && onclick([])


  };

  /* if(base64Files!=''){
      onclick(base64Files[0].base64.replace(base64Files[0].base64.slice(0,base64Files[0].base64.indexOf(',')+1),""))
    } */
  useEffect(() => {
    if (base64Files != "") {


      onclick(base64Files); // todo
      //base64Files[0].base64.replace(base64Files[0].base64.slice(0,base64Files[0].base64.indexOf(',')+1),"")
    }
  }, [base64Files]);

  return (
    <div className=" position-relative">
      <div>
        <input
          type="file"
          multiple={multiImg}
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
      </div>
      <div
        className="container-choose "
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {base64Files.map((file, index) => (
          <div key={index} className="container-img">
            <img
              src={file.base64}
              alt={file.name}
              style={{ width: "100px", height: "100px" }}
            />

            {/*               <textarea value={file.base64.replace(file.base64.slice(0,file.base64.indexOf(',')+1),"")} readOnly rows="5" cols="50" />
             */}
            <button onClick={() => handleDelete(index)}>x</button>
          </div>
        ))}
      </div>
    </div>
  );
}
