import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { faDAndD } from "@fortawesome/free-brands-svg-icons";
import { faBars, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainItems from "./MainItems";
import { ContextPro } from "../../App";

export default function CategoryList({ renderData }) {
  const { idUser } = useContext(ContextPro);

  return (
    <li className="link main-link">
      <FontAwesomeIcon icon={faBars} />
      <span className="ms-2">Categories</span>
      <ul className="item-main link-item">
        {renderData.map((cat, index) => {
          return !cat?.childs?.length ? (
            <li>
              <Link
                className="section-item"
                to={`${
                  idUser?.roles?.length ? "Admin" : ""
                }/product/category/${cat.name.split("/").join("-")}`}
                state={{
                  id: cat.id,
                }}
              >
                {cat.name}
              </Link>
            </li>
          ) : (
            <>
              <MainItems item={cat} />
            </>
          );
        })}
      </ul>
    </li>
  );
}
//<Link className="section-item">{e.name}</Link>
/*  {dataCate
          ? dataCate.map((e) =>
                <MainItems cond={e.id} title={e.name} data={dataCate} />
              ) : e.parentId == null ? (
                <li>
                  <Link className="section-item">{e.name}</Link>
                </li>
              ) : (
                ""
              )
            )
          : ""} */
