import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import CartProduct from "../page_offer/Cartproduct/CartProduct";

export default function SwiperViewProduct_Item({
  idp = "",
  title = "",
  children,
  breakpoints = {},
  spaceBetween = 50,
}) {
  return (
    <>
      <div className=" container product-row px-3 fs-3" id={idp}>
        <div className=" mb-5">{title}</div>

        <Swiper
          className=" mt-5 h-auto product-grid"
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={spaceBetween}
          slidesPerView={2}
          navigation={{
            enabled: true,
          }}
          breakpoints={{
            991: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            681: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
        >
          {children}

          {/*      <SwiperSlide>
            <div className="product-grid me-3">
              <CartProduct />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-grid me-3">
              <CartProduct />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-grid me-3">
              <CartProduct />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-grid me-3">
              <CartProduct />
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </>
  );
}
