import { useContext, useEffect, useRef, useState } from "react";

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../../App";

export default function Stepper({ onchange = () => {} }) {
  //const {count,setCount} =useContext(ContextPro)
  const ref = useRef();
  const [count, setCount] = useState(1);

  useEffect(() => {
    onchange(count);
  }, [count]);
  return (
    <div className="step">
      <input type="number" value={count} />
      <span>
        <FontAwesomeIcon
          icon={faAngleUp}
          values={count}
          onClick={() => {
            setCount(count + 1);
          }}
        />
        <FontAwesomeIcon
          icon={faAngleDown}
          values={count}
          onClick={() => {
            count == 1 ? setCount(1) : setCount(count - 1);
          }}
        />
      </span>
    </div>
  );
}
