import { Link } from "react-router-dom";

export default function Row2F({title ,children}){
    return(
        <div>
            <h6 className=" d-block text-white">{title}</h6>
            <div>
              {children}
            </div>

        </div>
    )
}