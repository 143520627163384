import { Link } from "react-router-dom";

import BtnLogin from "./BtnLogin";

import "./log.css";

export default function PageLogin({}) {
  return (
    <div className=" container-login fs-6">
      <h4>Sign in to your account</h4>
      <div className="btn-sign">
        <Link to={"/login/signin"}>
          <BtnLogin text={"Sign in"} />
        </Link>
      </div>
      <div className="btn-register">
        <Link to={"/login/register"}>
          <BtnLogin text={"Creat account"} />
        </Link>
      </div>
    </div>
  );
}
