import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

import CanvasInCenter from "../../Commons/CanvasInCenter";
import BtnAdmin from "../BtnAdmin";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd } from "../../../utils/utilsFuc";

function ClientOrder({ e, setData }) {
  const [open, setOpen] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  const [orderStatus, setOrederStatus] = useState(null);
  const [show, setShow] = useState(false);
  const Status = {
    1: "approved",
    2: "pending...",
    3: "rejected",
  };
  return (
    <div className="mt-4  ">
      <div
        onClick={() => setOpen(!open)}
        aria-controls="order-admin"
        aria-expanded={open}
        className=" d-flex justify-content-between align-items-center bg-success-subtle px-2 border-bottom border-1 border-black"
      >
        <span className="fs-5 fw-medium col-2 ">
          Client <div className=" fw-light">{e?.user?.name}</div>
        </span>
        <span className="fs-5 fw-medium col-2 ">
          Address <div className=" fw-light">{e?.address}</div>
        </span>
        <span className="fs-5 fw-medium col-2 ">
          Number <div className=" fw-light">{e?.user?.phoneNumber}</div>
        </span>
        <span className="fs-5 fw-medium col-2 ">
          Total <div className=" fw-light">{e?.totalAmount}</div>{" "}
        </span>
        <span className="fs-5 fw-medium col-2 ">
          Status{" "}
          <div
            style={{
              width: "fit-content",
            }}
            className={`fw-light  fs-6 text-white me-3  ${
              e?.orderStatus === 2 && "bg-warning"
            } ${e?.orderStatus === 3 && "bg-danger "}  ${
              e?.orderStatus === 1 && "bg-success"
            } py-2 px-3 rounded-2`}
          >
            {Status[e?.orderStatus]}
          </div>{" "}
        </span>
        <button className="fs-3 text-white my-2 px-4 border-0 bg-success">
          {open ? "-" : "+"}
        </button>
      </div>
      <Collapse in={open}>
        <div id="order-admin " className="">
          <div className=" px-2 d-flex justify-content-between align-items-center">
            <span
              style={{
                width: "90px",
                fontWeight: "bold",
                fontSize: "18px",
                flexGrow: "1",
              }}
            >
              title
            </span>
            <span
              style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
            >
              image
            </span>
            <span
              style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
            >
              count
            </span>
            <span
              style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
            >
              price
            </span>
            <span
              style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
            >
              discount
            </span>
            <span
              style={{ width: "90px", fontWeight: "bold", fontSize: "18px" }}
            >
              priceTotal
            </span>
          </div>
          {e?.orderItems?.map((e) => (
            <div className="p-2 d-flex justify-content-between align-items-center">
              <span
                style={{ width: "90px", wordBreak: "break-all", flexGrow: "1" }}
              >
                {e?.product?.title}
              </span>
              <img
                src={e?.product?.productAttchments[0]?.photo}
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
              />
              <span
                style={{
                  width: "90px",
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
              >
                {e?.count}
              </span>
              <span style={{ width: "90px", wordBreak: "break-all" }}>
                {e?.price}
              </span>
              <span style={{ width: "90px", wordBreak: "break-all" }}>
                {e?.discount}
              </span>
              <span style={{ width: "90px", wordBreak: "break-all" }}>
                {e?.totalAmount}
              </span>
            </div>
          ))}
          <BtnAdmin
            title={"Edit Status"}
            onclick={() => {
              setShow(true);
              setIdOrder(e?.id);
            }}
          />
        </div>
      </Collapse>
      <CanvasInCenter showcenter={show} setError={setShow}>
        <>
          <div
            className="my-4"
            style={{
              width: "360px",
            }}
          >
            <select
              value={orderStatus || e?.orderStatus}
              onChange={(e) => {
                setOrederStatus(e.currentTarget.value);
       
              }}
              className=" w-100 border border-1"
            >
              <option value={2}>Pending</option>
              <option value={1}>approved</option>
              <option value={3}>rejected</option>
            </select>
          </div>
          <div
            style={{
              width: "fit-content",
              margin: "auto",
            }}
          >
            <BtnAdmin
              title={"Save"}
              onclick={() => {
             

                urlApiEd(
                  adminPanelAPIs.Order.actionOrder(idOrder, orderStatus),
                  {},
                  {
                    "Content-Type": "application/json",
                    " Authorization": `Bearer ${localStorage.getItem("token")}`,
                  }
                ).then((e) => {
             
                  setData((prev) => prev?.map((t) => (t.id == e.id ? e : t)));
                  setShow(false);
                });
              }}
            />
          </div>
        </>
      </CanvasInCenter>
    </div>
  );
}
export default ClientOrder;
