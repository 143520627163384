import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AddSomeInfo from "./AddSomeInfo";
import { ContextPro } from "../../../App";
import BtnAdmin from "../BtnAdmin";
import ChooseImg from "../ChooseImg";
import InputComponent from "../InputComponent";

import { adminPanelAPIs } from "../../../utils/config";
import { Based64 } from "../../../utils/convertToBased64";
import { urlApiEd } from "../../../utils/utilsFuc";

export default function EditProduct() {
  const { dataBrand } = useContext(ContextPro);
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState([]);
  const [data, setData] = useState({
    name: location?.state?.name,
    price: location?.state?.price,
    discount: location?.state?.discount || 0,
    brand: location?.state?.brand,
    description: location?.state?.description,
    outofstock: location?.state?.out,
  });
  const [img, setImg] = useState([]);
  function getImg(imgs) {
    setImg([]);
    imgs?.map((e) => {
      setImg((prev) => [...prev, Based64(e)]);
    });
  }

  return (
    <div className=" container">
      <div className=" brand-data">
        <InputComponent title={"Name"}>
          <input
            type="text"
            value={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"Price"}>
          <input
            type="number"
            value={data.price}
            onChange={(e) => {
              setData({ ...data, price: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"Discount"}>
          <input
            type="number"
            value={data.discount}
            onChange={(e) => {
              setData({ ...data, discount: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"Brands"}>
          <select
            value={data.brand}
            onChange={(e) => {
              setData({ ...data, brand: e.target.value });
            }}
          >
            {dataBrand?.map((e) => (
              <option value={e.id}>{e.name}</option>
            ))}
          </select>
        </InputComponent>
        <InputComponent title={"Description"}>
          <textarea
            value={data.description}
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
          ></textarea>
        </InputComponent>

        <InputComponent title={"Photo File"}>
          <ChooseImg onclick={getImg} />
        </InputComponent>
        <div className=" align-self-center">
          <input
            type="checkbox"
            value={data.outofstock}
            checked={data.outofstock}
            onClick={(e) => {
              setData({ ...data, outofstock: e.target.checked });
            }}
          />
          <label>Out of stock</label>
        </div>
        <AddSomeInfo onclick={setInfo} />
      </div>
      <div className=" d-flex gap-4">
        <BtnAdmin
          title={"Creat"}
          bgcolor="green"
          onclick={() => {
            urlApiEd(adminPanelAPIs.Product.editProductURL, {
              id: parseInt(location?.state?.id),
              title: data.name,
              description: data.description,
              price: parseInt(data.price) || 0,
              discount: parseInt(data.discount),
              outOfStock: data.outofstock,
              categoryId: parseInt(location?.state?.categoryid),
              brandId: parseInt(data.brand),
            })
              .then((e) => {
                img?.map((img) => {
                  urlApiEd(
                    adminPanelAPIs.ProductAttchment.addProductAttchmentURL,
                    {
                      photo: img,
                      productId: e.id,
                    }
                  ).catch((e) => {});
                });
                info?.map((info) => {
                  info.key &&
                    urlApiEd(
                      adminPanelAPIs.ProductMoreInfo.addProductMoreInfoURL,
                      {
                        key: info.key,
                        value: info.value,
                        productId: e.id,
                      }
                    ).then((e) => {});
                });
                navigate(-1);
              })
              .catch((e) => {});
          }}
        />

        <BtnAdmin title={"Back to List"} bgcolor="blue" />
      </div>
    </div>
  );
}
/* import React, { useContext, useState } from "react";



export default function AddProduct() {
  const { dataBrand } = useContext(ContextPro);
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState([]);
  const [data, setData] = useState({
    categoryId: location.state.id,
              
   
  });
  const [img, setImg] = useState([]);
  function getImg(imgs) {
    setImg([]);
    imgs?.map((e) => {
      setImg((prev) => [...prev, Based64(e)]);
    });
  }
  console.log(data , location, dataBrand);

  return (
    <div className=" container">
      <div className=" brand-data">
        <InputComponent title={"Name"}>
          <input
            type="text"
            value={data.title}
            onChange={(e) => {
              setData({...data, title: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"Price"}>
          <input
            type="number"
            value={data.price}
            onChange={(e) => {
              setData({ ...data, price: parseInt(e.target.value) });
            }}
          />
        </InputComponent>
        <InputComponent title={"Discount"}>
          <input
            type="number"
            value={data.discount}
            onChange={(e) => {
              setData({ ...data, discount: parseInt(e.target.value) });
            }}
          />
        </InputComponent>
        <InputComponent title={"Brands"}>
          <select
            value={data.brandId}
            onChange={(e) => {
              setData({ ...data, brandId: e.target.value });
            }}
          >
            {dataBrand?.map((e) => (
              <option value={e.id}>{e.name}</option>
            ))}
          </select>
        </InputComponent>
        <InputComponent title={"Description"}>
          <textarea
            value={data.description}
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
          ></textarea>
        </InputComponent>

        <InputComponent title={"Photo File"}>
          <ChooseImg onclick={getImg} />
        </InputComponent>
        <div className=" align-self-center">
          <input
            type="checkbox"
            value={data.outOfStock}
            onChange={(e) => {
              setData({ ...data, outOfStock: e.target.value });
            }}
          />
          <label>Out of stock</label>
        </div>
        <AddSomeInfo onclick={setInfo} />
      </div>
      <div className=" d-flex gap-4">
        <BtnAdmin
          title={"Creat"}
          bgcolor="green"
          onclick={() => {
            urlApiEd(adminPanelAPIs.Product.addProductURL, data)
              .then((e) => {
                img?.map((img) => {
                  urlApiEd(
                    adminPanelAPIs.ProductAttchment.addProductAttchmentURL,
                    {
                      photo: img,
                      productId: e.id,
                    }
                  )
                    .then((e) => console.log(e))
                    .catch((e) => console.log(e));
                });
                info?.map((info) => {
                  urlApiEd(
                    adminPanelAPIs.ProductMoreInfo.addProductMoreInfoURL,
                    {
                      key: info.key,
                      value: info.value,
                      productId: e.id,
                    }
                  ).then((e) => console.log(e));
                });
                navigate(-1);
              })
              .catch((e) => console.log(e));
          }}
        />

        <BtnAdmin title={"Back to List"} bgcolor="blue" />
      </div>
    </div>
  );
}
 */
