import { useContext } from "react";

import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";
import TopBar from "../../public_componet/TopBar";

import { Admin, User } from "../../utils/AdminUser";
import { logout } from "../../utils/auth";

export default function WebTopBar() {
  return (
    <TopBar>
      <span>SAT-THU 10 AM to 6 PM</span>
      {(User() || Admin()) && (
        <button
          onClick={() => {
            logout();
            /* localStorage.clear()
      
        window.open('/',"_self") */
          }}
        >
          <FontAwesomeIcon icon={faSignOut} size="1x" />
          <span className=" px-2">Log Out</span>
        </button>
      )}
    </TopBar>
  );
}
