import { Link } from "react-router-dom";

export default function Links({ Linkto, title, subTitle }) {
  return (
    <Link to={Linkto}>
      <li className="link">
        <span className="link-text">{title}</span>
        <ul className="link-item">
          {subTitle
            ? subTitle?.map((e) => (
                <Link
                  className="section-item"
                  to={`/product/category/${e.name.split("/").join("-")}`}
                >
                  {e.name}
                </Link>
              ))
            : ""}
        </ul>
      </li>
    </Link>
  );
}
