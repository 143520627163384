import BottomBar from "./BottomBar";
import WebMidBar from "./WebMidBar";
import WebTopBar from "./webTopBar";

import { Admin } from "../../utils/AdminUser";

export default function WebHeader({ data }) {
  return (
    <div className={`header`}>
      <WebTopBar />
      <WebMidBar />
      <BottomBar data={data} />
    </div>
  );
}
