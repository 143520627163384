import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function SwiperComp({
  defaultModules = [Navigation, Pagination, Autoplay],
  modules = [],
  spaceBetween = 10,
  speed = 2000,
  slidesPerView = 8,
  renderedItems = [],
  className = "",
  loop = false,
  pagination = false,
  scrollbar = false,
  autoplay = false,
  breakpoints = {},
}) {
  return (
    <Swiper
      className={className}
      modules={[...defaultModules, ...modules]}
      spaceBetween={spaceBetween}
      speed={speed}
      slidesPerView={slidesPerView}
      navigation={{
        enabled: true,
      }}
      loop={loop}
      scrollbar={scrollbar}
      pagination={pagination}
      autoplay={autoplay}
      breakpoints={breakpoints}
    >
      {renderedItems.map((item, index) => (
        <SwiperSlide key={index + "Swipe"}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
}
