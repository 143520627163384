import { Link } from "react-router-dom";

import SwiperComp from "../Commons/SwiperComp";
import "../photo/ch3-240x240h.png";

export default function SwiperViewProduct() {
  return (
    <div className="product-row container product-row-view ">
      <SwiperComp
        
        spaceBetween={10}
        breakpoints={{
          991: {
            slidesPerView: 8,
          },
          768: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={3}
        renderedItems={[
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Monitors"}>
              <img src={require("../photo/ch3-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              MONTIORS
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Graphics Card"}>
              <img src={require("../photo/ch4-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              Graphics Card
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Gaming Chair"}>
              <img src={require("../photo/ch5-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              Gaming Chair
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Storage Devices"}>
              <img src={require("../photo/ch6-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              Storage Devices
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/SERVICES"}>
              <img src={require("../photo/ch7-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              SERVICES
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/ACCESSORIES"}>
              <img src={require("../photo/ch8-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              ACCESSORIES
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Cables - Connectors"}>
              <img src={require("../photo/ch9-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              Cables / Connectors
            </div>
          </div>,
          <div className=" d-flex flex-column align-items-center position-relative view-product ">
            <Link to={"/product/category/Network"}>
              <img src={require("../photo/ch11-240x240h.png")} alt="" />
            </Link>
            <div
              className=" text-uppercase fw-bold"
              style={{
                fontSize: "13px",
                letterSpacing: "-1px",
              }}
            >
              Network
            </div>
          </div>,
        ]}
        speed={500}
      />
    </div>
  );
}
