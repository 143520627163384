import { useContext, useEffect, useState } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import RangeSlider from "react-range-slider-input";
import { useParams } from "react-router-dom";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollsFilter from "./filter";
import Tip from "./tip";
import { ContextPro } from "../../../App";

import "react-range-slider-input/dist/style.css";

export default function FilterBody({
  dataFilter = [],
  title = [],
  hanldeFilterProducts,
  filterObj = {},
}) {
  const path = useParams();
  const { dataCate, dataBrand } = useContext(ContextPro);
  const [selected, setSelected] = useState(null);


  const handleChange = (event) => {
    setSelected(event);
    hanldeFilterProducts({
      name: "brandId",
      value: event,
    }); 

  };

  const handleReset = () => {
    setSelected(null);
  };

  useEffect(()=>{
    setSelected(null)
  },[path])
  
  return (
    <div className="row-filter px-3">
      <div className="row-filter-1">
        <div className="colls-open">
          <span className=" fw-bold fs-5">Filter</span>
          <div
            className="clear"
            onClick={() =>{ hanldeFilterProducts({ isClear: true });
          handleReset()}}
          >
            <FontAwesomeIcon icon={faXmark} /> clear
          </div>
        </div>
        {/*                {Object.keys(dataFilter)?.map(e=><CollsFilter data={dataFilter[e]}  title={e} boolOpen={true}/>)}
         */}
        <CollsFilter title={"Price"}>
          {/* <RangeSlider max={1000} min={0} defaultValue={[100, 1000]} /> */}
          <div className=" d-flex gap-3 mt-3">
            <div className=" w-50">
              <label className=" fs-6 fw-bold">MinPrice </label>
              <input
                style={{ border: "1px solid " }}
                value={filterObj?.minPrice || ""}
                type="text"
                onWheel={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => {
                  hanldeFilterProducts({
                    name: "minPrice",
                    value: e.target.value,
                  });
                }}
                className="d-block py-1 px-2 w-100"
              />
            </div>
            <div className=" w-50">
              <label className=" fs-6 fw-bold">MaxPrice </label>
              <input
                type="text"
                style={{ border: "1px solid " }}
                value={filterObj?.maxPrice || ""}
                onChange={(e) => {
                  !isNaN(e.target.value) &&
                    hanldeFilterProducts({
                      name: "maxPrice",
                      value: e.target.value,
                    });
                }}
                className="d-block py-1 px-2 w-100"
              />
            </div>
          </div>
        </CollsFilter>
        <CollsFilter title={"Out Of Stock"}>
          <div>
            <input
              type="checkbox"
              id="stock"
              checked={filterObj?.outOfStock || false}
              onChange={(e) => {
                hanldeFilterProducts({
                  name: "outOfStock",
                  value: e.target.checked,
                });
              }}
            />
            <label htmlFor="stock" className=" fs-5 ">
              out of stock{" "}
            </label>
          </div>
        </CollsFilter>
        {/* todo */}
        {path.type==='brand'?"":<CollsFilter title={"brand"}>
          <div className=" brand-filter">
            {dataBrand?.map((data) => (
              <Tip text={data.name}>
                <label htmlFor={data.name}  onClick={()=>{
                  handleChange(data.id)

                }}>
                  <input
                    type="radio"
                    name="brand"
                    checked={(selected===data.id&&Object?.keys(filterObj)?.length) ? true : false}
                    id={data.name}
                    value={data.id}
          
                  />
                  <img src={data.photo} alt="" srcset="" />
                </label>
              </Tip>
            ))}
          </div>
        </CollsFilter>}
      </div>
    </div>
  );
}
