import { useEffect, useRef, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link, useParams } from "react-router-dom";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CollsFilter({ data = [], title, boolOpen = true, children }) {
  const [open, setOpen] = useState(boolOpen);
  const path = useParams();
  const ref = useRef();

  useEffect(() => {
    setOpen(boolOpen);
  }, [path]);

  return (
    <div className="wrap-filter">
      <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="colls-open"
      >
        <div className=" fs-5 fw-bold">{title}</div>
        <FontAwesomeIcon icon={faAngleDown} rotation={open && 180} />
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text" ref={ref}>
          <div className="item-filter">
            {/* {data?.map(e=>{
                           
                            return(
                                <label> <input type="checkbox" /> {e}</label>
                            )
                        })} */}

            {children}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default CollsFilter;
