import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ContextPro } from "../../../App";
import BreadCrumb from "../../BreadCrumb";
import CanvasInCenter from "../../Commons/CanvasInCenter";
import Loader from "../../loader";
import MassageError from "../../MassageError";
import BtnAdmin from "../BtnAdmin";
import ChooseImg from "../ChooseImg";
import InputComponent from "../InputComponent";

import { adminPanelAPIs } from "../../../utils/config";
import { Based64 } from "../../../utils/convertToBased64";
import { urlApiEd } from "../../../utils/utilsFuc";

export default function AddBrand() {
  const { setDataBrand } = useContext(ContextPro);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    name: "",
  });
  const [img, setImg] = useState();
  const [error, setError] = useState(false);
  function getImg(imgs) {
    setImg();
    imgs?.map((e) => {
      setImg(Based64(e));
    });
  }
  function addBrand() {
    setLoader(true);
    urlApiEd(adminPanelAPIs.Brand.addBrandURL, {
      name: data.name,
      photo: `${img}`,
    })
      .then((e) => {
        navigate(-1);
        setDataBrand((prev) => [...prev, e]);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setError(true);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  return (
    <div className=" container">
      {/*  <BreadCrumb /> */}

      <div className=" brand-data">
        <InputComponent title={"Name"}>
          <input
            type="text"
            value={data.name}
            onChange={(e) => {
              setData({ name: e.target.value });
            }}
          />
        </InputComponent>
        <InputComponent title={"Photo File"}>
          {" "}
          <ChooseImg onclick={getImg} multiImg={false} />
        </InputComponent>
      </div>
      <div className=" d-flex gap-4">
        <BtnAdmin title={"Creat"} bgcolor="green" onclick={addBrand} />

        <BtnAdmin
          title={"Back to List"}
          bgcolor="blue"
          onclick={() => {
            navigate(-1);
          }}
        />
      </div>
      <CanvasInCenter showcenter={error} setError={setError}>
        <MassageError>Please,Enter Data</MassageError>
      </CanvasInCenter>
    </div>
  );
}
