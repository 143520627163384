export default function BtnLogin({ text }) {
    return (
      
            <div className="btn-login">
                <button>{text}</button>
            </div>
      
    )

};
