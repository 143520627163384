import axios from "axios";

export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
export async function urlApiGet(url, body = {}) {
  return await axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((e) => e.data.data)
    .catch((e) => e);
}
export function urlApiEd(url, body = {},header={}) {
  return axios
    .post(url, body, {
      headers: header
    })
    .then((e) => e.data.data);
}
