import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import AddCart from "../page_offer/Cartproduct/AddCart";
import Stepper from "../page_offer/Cartproduct/Stepper";
import Wish from "../page_offer/Cartproduct/Wish";
import { faCertificate, faCreditCard, faHeadphones, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoBlock from "./InfoBlock";
import { ContextPro } from "../../App";
import BtnAdmin from "../Admin/BtnAdmin";
import Loader from "../loader";

import { Admin } from "../../utils/AdminUser";

export default function InfoProduct({
  name,
  brand,
  out,
  price,
  data,
  photo,
  id,
  description,
  categoryid,
}) {
  const { dataBrand, idUser } = useContext(ContextPro);
  const [count, setCount] = useState(1);
  const location = useLocation();
  const nameBrand =
    dataBrand?.find((e) => e.id == data?.brandId)?.name ||
    dataBrand?.find((e) => e.id == brand)?.name;

  function getCount(counts) {
    setCount(counts);
  }


  return (
    <div className=" info-product">
      <div className="title-product">
        {name ? name : location?.state ? location?.state?.name : data?.title}
      </div>
      <div className=" product-stats">
        <ul>
          {data?.outOfStock || out ? (
            <li className=" out-stock">out stock</li>
          ) : (
            <li className=" in-stock">in stock</li>
          )}
        </ul>
        <div className="brand">
          <Link to={`${Admin() ? "/Admin" : ""}/product/brand/${nameBrand}`}>
            <img
              src={
                dataBrand?.find((e) => e.id == data?.brandId)?.photo ||
                dataBrand?.find((e) => e.id == brand)?.photo
              }
              alt=""
            />

            {dataBrand?.map(
              (e) => e.id == data?.brandId && <span>{e.name}</span>
            )}
          </Link>
        </div>
      </div>
      <div className="price">
        <div className="price-new">EGP {data?.price || price}</div>
        <span className="price-tax">Price including Taxes</span>
      </div>
      {localStorage.getItem("token") && idUser?.roles?.length ? (
        <Link
          to={"/Admin/product/Edit"}
          state={{
            id: id || data?.id,
            name: name || data?.title,
            brand: brand || data?.brandId,
            out: out || data?.outOfStock,
            price: price || data?.price,

            description: description || data?.description,
            categoryid: categoryid || data?.categoryId,
          }}
        >
          <BtnAdmin title={"Edit "} />
        </Link>
      ) : (
        <div className="wrap-btn">
          <div className="group-btn">
            {out || data?.outOfStock ? (
              <button
                disabled
                style={{
                  background: "#f10050b8",
                  color: "white",
                  padding: "10px 20px ",
                  border: "none",
                  width:"100%",
                  cursor:"not-allowed"
                }}
              >
                out of Stock
              </button>
            ) : (
              <>
                <div className="cart-btn">
                  <Stepper onchange={getCount}></Stepper>
                  <AddCart
                    id={data?.id || id || location?.state?.id}
                    count={count}
                    productPhoto={photo}
                    productPrice={data?.price || price}
                    totalAmount={data?.price * count || price * count}
                    productTitle={data?.title || name}
                  />
                </div>
               
              </>
            )} <Wish />
          </div>
        </div>
      )}
      <div className="content-of-info">
        <InfoBlock>
          <FontAwesomeIcon icon={faCreditCard} />
        </InfoBlock>
        <InfoBlock>
          <FontAwesomeIcon icon={faTruck} />
        </InfoBlock>
        <InfoBlock>
        <FontAwesomeIcon icon={faHeadphones} />
        </InfoBlock>
        <InfoBlock>
        <FontAwesomeIcon icon={faCertificate} />
        </InfoBlock>
      </div>
    </div>
  );
}
