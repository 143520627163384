import React, { useContext } from 'react'
import { ContextPro } from '../App'
import App from '../App';
  
  
 export const Admin = ()=>{
   
    const{idUser}=useContext(ContextPro);
    return localStorage.getItem('token')&&idUser?.roles?.length ? true :false
 }
 export const User = ()=>{
    const{idUser, SetIdUser}=useContext(ContextPro);
    return localStorage.getItem('token')&&!idUser?.roles?.length&&idUser!={}? true :false
 }
 export const tok = ()=>{
    if(localStorage.getItem('token')){
        return localStorage.getItem('token');
    }
   }
  

