export default function Loader() {
  return (
    <div
      style={{
        top: "0",
        left: "0",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        background: "#6f6f6f3d",
        zIndex: "99999",
      }}
    >
      <div className=" loader"></div>
    </div>
  );
}
