import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  faCartArrowDown,
  faPerson,
  faSearch,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";
import CanvasInCenter from "../../public_componet/Commons/CanvasInCenter";
import HoverCart from "../../public_componet/page_cart/HoverCart";
import PageLogin from "../../public_componet/PageLogin/PageLogin";

import { Admin, AdminUser, User } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiEd, urlApiGet } from "../../utils/utilsFuc";

export default function WebMidBar() {
  const { idcart, idUser } = useContext(ContextPro);
  const navigate = useNavigate();
  const path = useParams();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [blurResult, setBlurResult] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const i =
    JSON.parse(localStorage.getItem("cart"))?.reduce(
      (prev, current) => prev + current.totalAmount,
      0
    ) || idcart?.reduce((prev, current) => prev + current.totalAmount, 0);
  const searchProduct = (e) => {
    e
      ? urlApiGet(adminPanelAPIs.Product.getProductURL, {
          title: e,
        }).then((e) => {
          setResult(e.list);
        })
      : setResult([]);
    //setSearch(e.target.value);
  };
  useEffect(() => {
    /* urlApiGet(adminPanelAPIs.Product.getProductURL, {
      title: search,
    }).then((e) => {
      console.log(e);
    }) */
    const timeout = setTimeout(() => {
      searchProduct(search);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);
  useEffect(() => {
    setSearch("");
  }, [window.location.pathname]);

  return (
    <div className=" px-3 py-4 d-flex justify-content-between align-items-center container">
      <Link to="/">
        <img
          alt=""
          src={require("./logo.jpg")}
          style={{
            height: "40px",
          }}
        />
      </Link>

      <div
        onBlur={() => {
          blurResult &&
            setTimeout(() => {
              setBlurResult(false);
            }, 200);
        }}
        className=" input-search position-relative "
      >
        <input
          value={search}
          type="text"
          onFocus={() => {
            !blurResult && setBlurResult(true);
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div className="icon-search">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div
          onMouseDown={() => {
            !blurResult && setBlurResult(true);
          }}
          className="hover-cart"
          style={{
            display: `${result.length && blurResult ? "block" : "none"}`,
            transform: "translateY(40px)",
            opacity: "1",
            width: "100%",
            top: "-20px",
          }}
        >
          <div className=" position-relative hover-cart-1">
            <ul
              style={{
                maxHeight: "300px",
                overflow: "auto",
              }}
              onMouseEnter={() => {
                setBlurResult(true);
              }}
              onMouseDown={() => {
                setBlurResult(true);
              }}
            >
              {result?.map((e) => (
                <Link
                  onMouseDown={() => {
                    navigate(
                      `${idUser?.roles?.length ? "/Admin" : ""}/name/${
                        e.title.split(" ").join("-") + "/" + e.id
                      }`
                    );
                  }}
                >
                  <li className="mb-2 d-flex px-3">
                    <span className=" me-2">
                      <img
                        src={e?.productAttchments[0]?.photo}
                        alt=""
                        style={{ width: "50px", height: "60px" }}
                      />
                    </span>
                    <span>
                      <div style={{ width: "fit-content" }}>{e.title}</div>
                      <div style={{ width: "fit-content" }}>EGP {e.price}</div>
                    </span>
                  </li>
                </Link>
              ))}
              <Link
                to={`${Admin() ? "/Admin" : ""}/search/${search}`}
                /*   onMouseDown={() => {
                  navigate(`${idUser?.roles?.length?"/Admin":''}/search/${search}`);
                }} */
              >
                <li className="mb-2 d-flex px-3">View More</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="cart-account">
        {User() ? (
          <>
            <Link to={"/"}>
              <FontAwesomeIcon icon={faUser} size="1x" />
              <span>{idUser?.name}</span>
            </Link>
          </>
        ) : Admin() ? (
          <>
            <FontAwesomeIcon icon={faUser} size="1x" />
            <span>Admin</span>
          </>
        ) : (
          <CanvasInCenter
            rendertag={
              <>
                <FontAwesomeIcon icon={faUser} size="1x" />
                <span>My Account </span>
              </>
            }
          >
            <PageLogin />
          </CanvasInCenter>
        )}
        {/*  {Admin()?
             
               <>
                <FontAwesomeIcon icon={faUser} size="1x" />
                <span>My account a</span></>:""
              
        }
        {!Admin()&&!User() ? <CanvasInCenter
          rendertag={
            <>
             
                <FontAwesomeIcon icon={faUser} size="1x" />
                <span>My account sa</span>
              
            </>
          }
        >
          <PageLogin />
        </CanvasInCenter>:""} */}
        {Admin() ? (
          ""
        ) : (
          <div className=" mycart position-relative">
            <Link to={"/cart"}>
              <FontAwesomeIcon
                icon={faCartArrowDown}
                size="1x"
                flip="horizontal"
              />
              <span>
                {JSON.parse(localStorage.getItem("cart"))?.length ||
                  idcart[0]?.cartProducts?.length ||
                  `${0} `}
                item(s)-EGP {i}
              </span>
            </Link>
            <HoverCart />
          </div>
        )}
      </div>
    </div>
  );
}
