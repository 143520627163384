import { useContext, useEffect, useState } from "react";

import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartProduct from "./CartProduct";
import { ContextPro } from "../../../App";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd } from "../../../utils/utilsFuc";

export default function AddCart({
  id,
  count,
  productPhoto,
  productPrice,
  totalAmount,
  productTitle,
}) {
  const [load, setLoad] = useState(true);
  const [index, setindex] = useState(1);
  const [icount, setICount] = useState({});

  const { idcart, SetIdCart, setNotificationAdd, itemsCart, setItemsCart } =
    useContext(ContextPro);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setItemsCart(JSON.parse(localStorage.getItem("cart")) || []);
    }
  }, []);
  return (
    <div
      className="addcart-btn"
      onClick={() => {
        const info = {
          id,
          count,
          productPhoto: productPhoto,
          productPrice,
          totalAmount,
          productTitle,
        };
        
        let item = itemsCart || [];

        if (localStorage.getItem("token")) {
          urlApiEd(
            adminPanelAPIs.cartAdd.addCart,
            {
              productId: parseInt(id),
              count: parseInt(count),
            },
            {
              "Content-Type": "application/json",
              " Authorization": `Bearer ${localStorage.getItem("token")}`,
            }
          ).then((e) => {
            setNotificationAdd(info);
            urlApiEd(
              adminPanelAPIs.Cart.getCart,
              {},
              {
                "Content-Type": "application/json",
                " Authorization": `Bearer ${localStorage.getItem("token")}`,
              }
            ).then((e) => {
              SetIdCart(e.list);
            });
          });
        }
        if (!localStorage.getItem("token")) {
          item?.push(info);

          const datacatr = item.filter(
            ({ id }, index) => !item.map(({ id }) => id).includes(id, index + 1)
          );
          localStorage.setItem("cart", JSON.stringify(datacatr));
          const carts = JSON.parse(localStorage.getItem("cart"));
          SetIdCart(carts);
       

          setNotificationAdd(info);
        }
      }}
    >
      <FontAwesomeIcon icon={faCartArrowDown} flip="horizontal" />
      Add Cart
    </div>
  );
}
