import { useContext } from "react";
import { Link } from "react-router-dom";

import {
  faPenFancy,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";
import TopBar from "../../public_componet/TopBar";

import { Admin, User } from "../../utils/AdminUser";

export default function MobTopBar() {
  const { idUser } = useContext(ContextPro);

  return (
    <TopBar>
      <div className="cart-account">
        {User() || Admin() ? (
          <>
            <div>
              <FontAwesomeIcon icon={faUser} />
              <span className=" mx-1">{Admin() ? "Admin" : idUser?.name}</span>
            </div>

            <div
              className="regs text-white"
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.clear();
                window.open("/", "_self");
              }}
            >
              <FontAwesomeIcon icon={faSignOut} size="1x" />
              <span className="mx-0">Log Out</span>
            </div>
          </>
        ) : (
          <>
            <Link to={"/login"} className="log text-white ">
              <FontAwesomeIcon icon={faUser} />
              Login
            </Link>
            <Link to={"/login/register"}>
              <div className="regs text-white">
                <FontAwesomeIcon icon={faPenFancy} />
                Regiseter
              </div>
            </Link>
          </>
        )}
      </div>
    </TopBar>
  );
}
/* <button onClick={()=>{
        localStorage.clear()
        window.open('/',"_self")
      }}><FontAwesomeIcon icon={faSignOut} size="1x" />
           <span className=" px-2">Log Out</span></button> */
