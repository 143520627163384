import "./Admin.css";

export default function BtnAdmin({ bgcolor = "#ff0055", title, onclick }) {
  return (
    <div className=" btn-admin">
      <button
        onClick={onclick}
        style={{
          backgroundColor: `${bgcolor}`,
          fontSize: "13px",
          
        }}
      >
        {" "}
        {title}
      </button>
    </div>
  );
}
