import { Link } from "react-router-dom";

export default function TotalPrice({data}) {
  return (
    <div className=" total-cart">
      <div className="price-total">
        <div className="sub-total">
          {" "}
          <span className=" me-3">Sub-Total</span> EGP {data}
        </div>
        {/* <div className="discount">
          <span className=" me-3">VAT(14%)</span>EGP5,3544
        </div>
        <div className="total">
          <span className=" me-3">Total</span>EGP5,3544
        </div> */}
      </div>
      <div className="btns-cart">
        <Link reloadDocument to={"/cart"}>
          <button className="btn-view">view cart</button>
        </Link>
        
      </div>
    </div>
  );
}
