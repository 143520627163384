import React from 'react'

export default function TaHeadAdmin({arrtitle=[]}) {
  return (
    <thead>
        <tr>
            {arrtitle.map(e=>(
                <td>{e}</td>
            ))}
        </tr>
    </thead>
  )
}
