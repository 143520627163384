import { useEffect, useState } from "react";
import axios from "axios";
import CanvasInCenter from "../Commons/CanvasInCenter";
import{adminPanelAPIs} from'../../utils/config'
export default function Register() {
  const [error, setError] = useState(false);
  const [data, setdata] = useState({
    name: "",
    emai: "",
    phone: "",
    password: "",
  });
  
 /*  useEffect(() => {
    setError(error)
  }, [data,error]); */
  function fetchuser(name, emai, phone, password) {
    axios
      .post(adminPanelAPIs.Account.userRegister ,
        {
          name: `${data.name}`,
          email: `${data.emai}`,
          phoneNumber: `${data.phone}`,
          password: `${data.password}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((e) => {
       localStorage.setItem('token',e.data.data.token)
       window.open('/',"_self")
      })
      .catch(() => {
        setError(true)
       
      });
  }
  return (
    <div className=" container-login fs-6  sign-in">
      <form action="" className=" d-flex flex-column gap-4 border-secondary">
        <label>
          {" "}
          <span>Name</span>
          <input
            type="text"
            placeholder="Enter Name"
            value={data.name}
            className="  w-100"
            onChange={(e) => {
              setdata({ ...data, name: e.target.value });
            }}
          />
        </label>
        <label>
          {" "}
          <span>Email</span>
          <input
            type="text"
            placeholder="Enter Email"
            value={data.emai}
            className="  w-100"
            onChange={(e) => {
              setdata({ ...data, emai: e.target.value });
            }}
          />
        </label>
        <label>
          {" "}
          <span>Phone</span>
          <input
            type="tel"
            placeholder="Enter Phone"
            value={data.phone}
            className="  w-100"
            onChange={(e) => {
              setdata({ ...data, phone: e.target.value });
            }}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            type="password"
            placeholder="Enter Password"
            className=" w-100"
            value={data.password}
            onChange={(e) => {
              setdata({ ...data, password: e.target.value });
            }}
          />
        </label>
        <div className=" btn-login">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();

              fetchuser(data.name, data.emai, data.phone, data.password);
            }}
          >
            Register
          </button>
        </div>
        <CanvasInCenter showcenter={error}  setError={setError}><div className=" my-5">palese enter correct data</div></CanvasInCenter>
   
      </form>
    </div>
  );

  
}
