
const logoutChannel = new BroadcastChannel('logout');

export const login = (token) => {
    localStorage.setItem("token", token)
    //window.history.push('/')
}

export const checkToken = () => {
    const token = localStorage.getItem("token", )
    if(!token) return alert('You are not logged in')
    return token
    
}

export const logout = () => {
    logoutChannel.postMessage("Logout")
    //localStorage.removeItem("token", 'this_is_a_demo_token' )
    localStorage.clear()
    window.location.href = window.location.origin + "/";

}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logout();
        logoutChannel.close();
       
        
    }
}