import { useEffect, useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation } from "react-router-dom";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./common.css";

export default function CanvasInCenter({
  children,
  rendertag = [],
  showcenter = false,
  setError,
}) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const handleClose = () => {
    setShow(false);
    if (showcenter == true) {
      setError(false);
    }
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(showcenter);
  }, [showcenter]);
  useEffect(() => {
    handleClose();
  }, [location]);
  return (
    <>
      <div onClick={handleShow}>{rendertag}</div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdropClassName="canvas-center"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
