

export default function TopBar({children}){
  return(
    <div className="bg-black py-1 ">
        <div className="px-3 d-flex justify-content-between align-items-center text-white container ">
            {children}
        </div>
    </div>
  )
}